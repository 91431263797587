import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions/authActions';
import './SetNewPassword.scss';
import { Navigate } from 'react-router-dom';
import ErrorToast from '../ErrorToast/ErrorToast';
import Spinner from '../Spinner/Spinner';
import logo from '../../assets/images/arena-logo-normal-svg.svg';

export class SetNewPassword extends Component {
  handleSetPassword = e => {
    e.preventDefault();
    const { setPassword, 
       auth: { loginErrors },
    clearLoginErrors
  } = this.props;
  if (loginErrors.length) clearLoginErrors();
    const email = localStorage.getItem('userMail');
    const password = this.password.value;
    const confirmPassword = this.confirmPassword.value;

    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    setPassword({ email, password, confirmPassword });
  };

  render() {

    const {
      auth: { passwordSet, user, isLoading, loginErrors }
    } = this.props;

    if (passwordSet) {
      return <Navigate to="/business" />;
    }
    return (
      <section className="form__wrapper">
        <div className="form">
        <img className="form__logo" src={logo} alt="logo" />
          <h4 className="form__appname">Store Manager</h4>
          <h4 className="form__header">Set New Password</h4>
          <form className="form__login" onSubmit={this.handleSetPassword}>
          {loginErrors.length ? <ErrorToast errors={loginErrors} /> : null}
            <div className="form__control">
              <input
                type="password"
                autoFocus
                className="form__input"
                // id="login-password"
                placeholder="New Password"
                ref={password => (this.password = password)}
                required
              />
            </div>
            <div className="form__control">
              <input
                type="password"
                autoFocus
                className="form__input"
                placeholder="Confirm Password"
                ref={confirmPassword => (this.confirmPassword = confirmPassword)}
                required
              />
            </div>
            <div className="form__control">
              <button type="submit" className="btn btn--gradient full-width">
              {isLoading ? <Spinner /> : 'Set Password' }</button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

SetNewPassword.propTypes = {
  setPassword: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    passwordSet: PropTypes.bool,
    user: PropTypes.string,
    isLoading: PropTypes.bool,
    loginErrors: PropTypes.array
  }).isRequired,
  clearLoginErrors: PropTypes.func.isRequired
};

const mapStateToProp = state => ({
  auth: state.auth
});

const mapActionsToProps = { setPassword: authActions.setPassword, clearLoginErrors: authActions.clearLoginErrors };

export default connect(mapStateToProp, mapActionsToProps)(SetNewPassword);