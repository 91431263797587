import axios from "axios";

// Base URL for your API
const BASE_URL = "https://api.thearenang.com:8081/api/v1";
// const BASE_URL = "http://127.0.0.1:8081/api/v1";

// Create an Axios instance with the base URL
const apiClient = axios.create({
  baseURL: BASE_URL,
  // timeout: 10000, // Set timeout for requests (optional)
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request interceptor
apiClient.interceptors.request.use(
    (config) => {
      // Skip adding Authorization for specific endpoints
      const skipAuthEndpoints = ["/user/auth/login", "/user/auth/register","/user/auth/reset-password/**", "/forgot-password", "/products" , "/category/all"]; // Add all unauthenticated routes here
      const isSkipAuthEndpoint = skipAuthEndpoints.some((endpoint) =>
        config.url.includes(endpoint)
      );
  
      if (!isSkipAuthEndpoint) {
        const token = localStorage.getItem('token'); // Or use cookies/session
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  // Add response interceptor (optional)
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle response errors globally
      if (error.response?.status === 401) {
        // Optional: Perform logout or redirect
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

export default apiClient;
