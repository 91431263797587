import React from 'react';
import Helmet from 'react-helmet';
import Main from '../../components/Main/Main';
import GetStartedForm from '../../components/RegisterForm/BizGetStartedForm';

const GetStartedPage = () => (
  <>
    <Helmet>
      <title>Get Started - Arena Storefront</title>
    </Helmet>
    <Main>
      <GetStartedForm />
    </Main>
  </>
);

export default GetStartedPage;
