import React, { useState, useEffect } from 'react';
import Logo from '../assets/images/arena-logo-white.svg';
import filter from '../assets/images/filter.svg';
import img1 from '../assets/images/craft.jpg';
import grid from '../assets/images/grid.svg';
import viewList from '../assets/images/view-list.svg';
import PageHeader from '../components/sections/PageHeader';
import Button from '../components/elements/Button';
import { getAllProducts, getCategories } from '../network/product_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../assets/scss/components/_search.scss';

const Shop = () => {
    const [viewMode, setViewMode] = useState('grid');
    const [showFilters, setShowFilters] = useState(true);
    
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(40);
    const [totalPages, setTotalPages] = useState(0);
    const [totalNumber, setTotalNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          setLoading(true);
          const response = await getAllProducts(page,size);
          setProducts(response["content"]); // Get first 8 products
          setTotalPages(response["totalPages"]);
          setTotalNumber(response["totalElements"]);
          setLoading(false);
        } catch (err) {
          setError('Failed to fetch products');
          setLoading(false);
        }
      };
      
      // Get list of categories
      const fetchCategories = async () => {
        try {
          const data = await getCategories();
          setCategories(data["message"]);
        } catch (err) {
        }
      };
      fetchCategories();
    
      fetchProducts();
    }, [page, size]);

    useEffect(() => {
      const results = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(results);
    }, [searchTerm, products]);

    const renderProducts = () => {
        if (loading) return <div>Loading products...</div>;
        // if (error) return <div>{error}</div>;
        return (

            <div className={`shop products-${viewMode}`}>
            <div className="shop products-list">
            {(searchTerm ? filteredProducts : products).map((prod) =>  {
        const discount = prod.price * (prod.discountPercent / 100);
        return <a key={prod.id} href='/product' style={{textDecoration:"none"}}
            onClick={() => {
                // Store the product data in localStorage before navigation
                localStorage.setItem('selectedProduct', JSON.stringify(prod));
            }} >
              <div className="product-item" key={prod.id}>
                <img src={prod.imageUrl} alt={prod.name} />
                { prod.dicountpercent != null || prod.dicountpercent > 0 ?
                <div className='discount-label' >-{prod.dicountpercent}%</div>
                :
                // <div className='new-label' >New</div>
                <></>
                }
                <div className='item-bottom'>
                <p className='item-name'>{prod.name}</p>
                <p className='item-desc'>{prod.description && (prod.description.substring(0, 30))}</p>
                {prod.dicountpercent != null || prod.dicountpercent > 0 ? <p className='item-price'><span>₦{prod.price - discount}</span>&nbsp;<span className='item-oldPrice'><s>{ prod.dicountpercent != null || prod.dicountpercent > 0 ? `₦${prod.price}` : ''}</s></span></p> : <p className='item-price'><span>₦{prod.price}</span></p>}
                </div>
                </div>
      
              </a>
            }
            )}
          </div>
          </div>
        );
      };

    const filterProductsByCategory = (products) => {
      if (selectedCategory === 'all') return products;
      return products.filter(product => product.category.id === selectedCategory);
    };

    return (
      <React.Fragment>
      <PageHeader page="Shop"></PageHeader>
      <div className='shop-band'>
      <section className='sb-left'>
      <div className='filter'>
        <i className='f-icon' onClick={() => setShowFilters(!showFilters)}>
        <img src={filter}/>
        </i>
        <i className='f-icon' onClick={() => setViewMode('grid')}>
        <img src={grid} style={{opacity: viewMode === 'grid' ? 1 : 0.5}}/>
        </i>
        <i className='f-icon' onClick={() => setViewMode('list')}>
        <img src={viewList} style={{opacity: viewMode === 'list' ? 1 : 0.5}}/>
        </i>
      </div>
      <p className='page-numbers'>Showing {page * size + 1}-{Math.min((page + 1) * size, totalNumber)} of {totalNumber}</p>
      </section>
      <section className='sb-right'>
      </section>
      </div>

      <div className="search-container">
      <div className="search-wrapper">
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      <input
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      </div>
      </div>

      <div className="shop-container">
      <div className="shop-layout">
        <aside className={`categories-sidebar ${showFilters ? 'show' : 'hide'}`}>
        <h3>Categories</h3>
        <ul className="categories-list">
          <li 
          className={selectedCategory === null ? 'active' : ''}
          onClick={() => setSelectedCategory(null)}
          >
          All Products
          </li>
          {categories.map(category => (
          <li
            key={category.id}
            className={selectedCategory === category.id ? 'active' : ''}
            onClick={() => {
            setSelectedCategory(category.id);
            setSearchTerm(' ');
            const filtered = products.filter(product => product.category.id === category.id);
            setFilteredProducts(filtered);
            }}
          >
            {category.value}
            <span className="category-count">
            ({products.filter(p => p.category.id === category.id).length})
            </span>
          </li>
          ))}
        </ul>
        </aside>
        
        <div className="products-section">
        {renderProducts()}
        </div>
      </div>
      </div>
      
      <div style={{textAlign:"center", margin:"15px 0px"}}>
      <Button 
      className="button-primary"
      onClick={() => setPage(page - 1)}
      disabled={page === 0}
      style={{marginRight: '10px'}}>
      {/* <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> */}
      </Button>
      <span style={{margin: '0 10px'}}>
      {page + 1} of {totalPages}
      </span>
      <Button 
      className="button-primary"
      onClick={() => setPage(page + 1)}
      disabled={page >= totalPages - 1}>
      {/* <FontAwesomeIcon icon="fa-solid fa-chevron-right" /> */}
      </Button>
      </div>
      </React.Fragment>
    );
}

export default Shop;