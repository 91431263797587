import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import playstore from '../../assets/images/playstore.svg';
import appstore from '../../assets/images/appstore.svg';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class News extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Browse The Range',
      paragraph: 'To find a diverse array of handcrafted products, vintage items, and craft supplies.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className='download-btn'>
          <a href="https://play.google.com/store/apps/details?id=com.thearenang.arena&pcampaignid=web_share " style={{margin:"10px 10px"}}>
          <img src={playstore} height="60px" />
          </a>
            &nbsp; &nbsp;
            <img src={appstore} height="60px" />
          </div>
          <div className={innerClasses} style={{paddingTop:"10px"}}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow" style={{backgroundImage:""}}>
                  <figure className="news-item-image m-0">
                    <Image
                    className="imggg"
                      src={require('./../../assets/images/bags.jpg')}
                      alt="News 01"
                      width={344}
                      height={194} >
                        </Image>
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a>Handmade Products</a>
                      </h3>
                      <p className="mb-16 text-sm">
                      These include artisanal goods crafted with care and creativity by skilled artisans. From jewelry and pottery to textiles and leather goods, handmade products on Arena reflect the unique styles and talents of creators.
                      </p>
                    </div>
                    {/* <div className="news-item-more text-xs mb-8">
                      <a href="https://cruip.com">Read more</a>
                    </div> */}
                  </div>
                  <p style={{textAlign:"center"}}>Handmade</p>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                    className="imggg"
                      src={require('./../../assets/images/vintage.jpg')}
                      alt="News 02"
                      width={344}
                      height={194} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a>Vintage Items</a>
                      </h3>
                      <p className="mb-16 text-sm">
                      For those seeking unique and nostalgic treasures, Arena offers a selection of vintage items curated with care. Whether it's vintage clothing, home decor, or collectibles, customers can discover one-of-a-kind pieces with character and history.
                      </p>
                    </div>
                    {/* <div className="news-item-more text-xs mb-8">
                      <a href="https://cruip.com">Read more</a>
                    </div> */}
                  </div>
                  <p style={{textAlign:"center"}}>Vintage</p>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <figure className="news-item-image m-0">
                    <Image
                    className="imggg"
                      src={require('./../../assets/images/craft.jpg')}
                      alt="News 03"
                      width={344}
                      height={194} />
                  </figure>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      <h3 className="news-item-title h4 mt-0 mb-8">
                        <a>Craft Supplies</a>
                      </h3>
                      <p className="mb-16 text-sm">
                      In addition to finished products, Arena caters to craft enthusiasts by providing a range of high-quality craft supplies. From fabrics and beads to tools and notions, artisans and hobbyists alike can find the materials they need to unleash their creativity and bring their ideas to life.
                      </p>
                    </div>
                    {/* <div className="news-item-more text-xs mb-8">
                      <a href="https://cruip.com">Read more</a>
                    </div> */}
                  </div>

                  <p style={{textAlign:"center"}}>Craft Supplies</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;