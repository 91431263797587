import React, { useState, useEffect } from 'react';
import PageHeader from '../components/sections/PageHeader';
import Button from '../components/elements/Button';
import { faDeleteLeft, faDumpster, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useNavigate } from 'react-router-dom';
import { deleteOrder, getAllOrders, updateOrder } from '../network/order_api';

const Cart = () => {
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedVendors, setExpandedVendors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchCartItems();
    }, []);

    const fetchCartItems = async () => {
        try {
            setLoading(true);
            const response = await getAllOrders('pending');
            // Group orders by business
            const groupedOrders = response.data.reduce((acc, order) => {
                const businessName = order.business.businessName;
                if (!acc[businessName]) {
                    acc[businessName] = {
                        vendor: businessName,
                        items: [],
                        address: order.business.address,
                        phone: order.business.phoneNumber.toString(),
                        email: order.business.email
                    };
                }
                // // Add products to the business group
                // order.products.forEach(product => {
                    const product = order.products;
                    if (product) {
                        acc[businessName].items.push({
                            id: product.id,
                            product: product.name,
                            order_id: order.id,
                            quantity: order.quantity,
                            category: product.category.value,
                            description: order.description,
                            price: product.price
                        });
                    }
                // });
                return acc;
            }, {});

            // Convert the grouped object to array format
            setCart(Object.values(groupedOrders));
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch cart items');
            setLoading(false);
        }
    };

    if (loading) return <div>Loading cart...</div>;
    if (error) return <div>{error}</div>;


    const toggleVendor = (vendor) => {
        setExpandedVendors(prevState => ({
            ...prevState,
            [vendor]: !prevState[vendor]
        }));
    };

    const increaseQuantity = (vendorIndex, itemIndex) => {
        const newCart = [...cart];
        newCart[vendorIndex].items[itemIndex].quantity += 1;
        setCart(newCart);
        updateOrder(cart[vendorIndex].items[itemIndex].order_id, { quan: newCart[vendorIndex].items[itemIndex].quantity });
    };

    const decreaseQuantity = (vendorIndex, itemIndex) => {
        const newCart = [...cart];
        if (newCart[vendorIndex].items[itemIndex].quantity > 1) {
            newCart[vendorIndex].items[itemIndex].quantity -= 1;
            setCart(newCart);
            updateOrder(cart[vendorIndex].items[itemIndex].order_id, { quan: newCart[vendorIndex].items[itemIndex].quantity });
        }
    };

    const removeItem = (vendorIndex, itemIndex) => {
        const newCart = [...cart];
        try {
            // Remove item from the database
            deleteOrder(cart[vendorIndex].items[itemIndex].order_id).then((response) => {
                
                
                if (response.status < 300) {
                    const modal = window.confirm('Item removed from cart!');
                    newCart[vendorIndex].items.splice(itemIndex, 1);
                    setCart(newCart);
                    // if (modal) {
                    //     window.location.href = '/cart';
                    // } else {
                    //     window.location.href = '/shop';
                    // }
                }
            }
            );
        } catch (err) {
            alert('Failed to remove item');
        }
    };

    const checkoutVendor = (vendorIndex) => {
        const vendor = [cart[vendorIndex]];

        navigate('/checkout', { state: { vendor } });
    };

    const checkoutAll = () => {
        const vendor = cart;
        navigate('/checkout', { state: { vendor } });
    };


    return (
        <React.Fragment>
            <PageHeader page="Cart" />
            <div className='cart_container'>
                <section className='cart_left'>
                    <table>
                        <thead>
                            <tr>
                                <th>Business</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                {cart.map((vendor, vendorIndex) => (
                    <tr key={vendor.vendor} className="cart_item">
                        <td>{vendor.vendor}</td> 
                        <td>{vendor.items.length} item(s)</td>
                        <td>₦{vendor.items.reduce((acc, item) => acc + item.quantity * item.price, 0)}</td>
                        <td>
                        <Button className='button-primary' onClick={() => toggleVendor(vendor.vendor)}>
                            {expandedVendors[vendor.vendor] ? 'Hide Items' : 'Show Items'}
                        </Button>&nbsp;
                        {expandedVendors[vendor.vendor] && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Subtotal</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendor.items.map((item, itemIndex) => (
                                        <tr key={item.id}>
                                            <td>{item.product}</td>
                                            <td>
                                                <button className='button-primary' onClick={() => decreaseQuantity(vendorIndex, itemIndex)} style={{    minWidth: "30px"}}>-</button>
                                                <span className='cart-quan'>{item.quantity}</span>
                                                <button className='button-primary' onClick={() => increaseQuantity(vendorIndex, itemIndex)} style={{    minWidth: "30px"}}>+</button>
                                            </td>
                                            <td>₦{item.price}</td>
                                            <td>₦{item.quantity * item.price}</td>
                                            <td>
                                                <button className='button-primary' onClick={() => removeItem(vendorIndex, itemIndex)} style={{    minWidth: "30px"}}><FontAwesomeIcon icon={faRemove}></FontAwesomeIcon></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        &nbsp;<Button className='button-primary' onClick={() => checkoutVendor(vendorIndex)}>Checkout</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
                    </table>

                </section>
                <section className='cart_right'>
                    <h2>Cart Totals</h2>
                    <span>
                    <p>Subtotal: </p>
                    <p>&nbsp;</p>
                    </span>
                    <span>
                    <p>Cart Total: </p>
                    <p className='total'>
                    {cart.reduce((total, vendor) => 
                        total + vendor.items.reduce((acc, item) => acc + item.quantity * item.price, 0), 0)}
                        </p>
                    </span>
                    {/* <Button className='button-primary' onClick={() => checkoutAll()}>Proceed to Checkout All</Button> */}
                </section>
            </div>
        </React.Fragment>
    );
}

export default Cart;