// import React from "react";
// import "./VendorProfile.scss";

// const VendorProfile = ({ vendor }) => {
//   const {
//     name,
//     avatar,
//     description,
//     products,
//     vendor,
//     location,
//     categories,
//     socialLinks,
//   } = vendor;

//   return (
//     <div className="vendor-profile">
//       {/* Vendor Header */}
//       <div className="vendor-header">
//         <img src={avatar} alt={name} className="vendor-avatar" />
//         <div className="vendor-info">
//           <h2 className="vendor-name">{name}</h2>
//           <p className="vendor-description">{description}</p>
//           <div className="vendor-location">Location: {location}</div>
//         </div>
//       </div>

//       {/* Vendor Details */}
//       <div className="vendor-details">
//         <div className="vendor-section">
//           <h3>Categories</h3>
//           <ul className="vendor-categories">
//             {categories.map((category, index) => (
//               <li key={index}>{category}</li>
//             ))}
//           </ul>
//         </div>

//         <div className="vendor-section">
//           <h3>Contact Information</h3>
//           <p>Email: {vendor.email}</p>
//           <p>Phone: {vendor.phone}</p>
//         </div>

//         <div className="vendor-section">
//           <h3>Social Links</h3>
//           <ul className="social-links">
//             {socialLinks.map((link, index) => (
//               <li key={index}>
//                 <a href={link.url} target="_blank" rel="noopener noreferrer">
//                   {link.platform}
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>

//       {/* Vendor Products */}
//       <div className="vendor-products">
//         <h3>Products</h3>
//         <div className="product-list">
//           {products.map((product) => (
//             <div className="product-card" key={product.id}>
//               <img src={product.image} alt={product.name} className="product-image" />
//               <h4 className="product-name">{product.name}</h4>
//               <p className="product-price">${product.price}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VendorProfile;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as vendorActions from '../../actions/profileActions/profileActions';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import Util from '../../utils';
import './VendorProfile.scss';
import CreateProductModal from '../Modals/CreateProductModal';
import UpdateProductModal from '../Modals/UpdateProductModal';
import DeleteProductModal from '../Modals/DeleteProductModal';
import ToastContainer from '../Toasts/ToastContainer';

class VendorProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productData: {},
      createModalIsOpen: false,
      updateModalIsOpen: false,
      deleteModalIsOpen: false,
    };
  }

  componentDidMount() {
    const { getProfile } = this.props;
    getProfile(); // Fetch vendor details
    // fetchVendorProducts(); // Fetch products associated with this vendor
  }
  
  openUpdateModal = (productData) => {
    this.setState({ updateModalIsOpen: true, productData });
  };

  openDeleteModal = (productData) => {
    this.setState({ deleteModalIsOpen: true, productData });
  };

  openCreateModal = () => {
    this.setState({ createModalIsOpen: true });
  };

  closeModal = () => {
    const { clearModalErrors } = this.props;
    this.setState({
      createModalIsOpen: false,
      updateModalIsOpen: false,
      deleteModalIsOpen: false,
    });
    clearModalErrors();
  };
  
  render() {
    const {
      vendorDetail,
      vendorDetail: { vendor,
       meta, isLoading,
      modalErrors,
      modalLoading,}
      // isLoading,
      // modalErrors,
      // modalLoading,
    } = this.props;
    
    const { createModalIsOpen, productData, updateModalIsOpen, deleteModalIsOpen } = this.state;

    if (isLoading) {
      return <Loading title="Loading Vendor Profile..." />;
    }

    return (
      <section className="vendor-details">
        <ToastContainer message={vendor.actionMessage} />

        <button
          type="button"
          className="btn btn--gradient"
          onClick={this.openCreateModal}
        >
          Profile Settings
        </button>
      {/* Vendor Information */}
      <div className="vendor-header">
        <img
          src={vendor.avatar}
          alt={`${vendor.name} Avatar`}
          className="vendor-avatar"
          style={{ borderRadius: "50%", width: "150px", height: "150px" }}
        />
        <h1>{vendor.name}</h1>
        <p className="vendor-description">{vendor.description}</p>
        <p><strong>Location:</strong> {vendor.location}</p>
        <div className="vendor-categories">
          <strong>Categories:</strong>
          <ul>
            {vendor.categories.map((category, index) => (
              <li key={index}>{category}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Contact Information */}
      <div className="vendor-contact-info">
        <h3>Contact Info</h3>
        <p><strong>Email:</strong> <a href={`mailto:${vendor.contactInfo.email}`}>{vendor.email}</a></p>
        <p><strong>Phone:</strong> <a href={`tel:${vendor.contactInfo.phone}`}>{vendor.phone}</a></p>
      </div>

      {/* Social Links */}
      <div className="vendor-social-links">
        <h3>Follow Us</h3>
        <ul>
          {vendor.socialLinks.map((link, index) => (
            <li key={index}>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link.platform}
              </a>
            </li>
          ))}
        </ul>
      </div>

        

        

        {/* {products.length && <Pagination entity={products} allProps={this.props} />}

        <UpdateProductModal
          modalState={updateModalIsOpen}
          product={productData}
          closeModal={this.closeModal}
          updateProduct={updateProduct}
          modalErrors={modalErrors}
          modalLoading={modalLoading}
        />
        <DeleteProductModal
          modalState={deleteModalIsOpen}
          product={productData}
          closeModal={this.closeModal}
          deleteProduct={deleteProduct}
          modalErrors={modalErrors}
          modalLoading={modalLoading}
        /> */}
      </section>
    );
  }
}

VendorProfile.propTypes = {
  getProfile: PropTypes.func.isRequired,
  // fetchVendorProducts: PropTypes.func.isRequired,
  // createProduct: PropTypes.func.isRequired,
  // deleteProduct: PropTypes.func.isRequired,
  // updateProduct: PropTypes.func.isRequired,
  clearModalErrors: PropTypes.func.isRequired,
  // vendorDetail: PropTypes.shape({
    vendorDetail :PropTypes.object.isRequired,
  // products: PropTypes.array.isRequired,
  // isLoading: PropTypes.bool,
  // modalErrors: PropTypes.array,
  // modalLoading: PropTypes.bool,
// }).isRequired,
};

const mapStateToProps = (state) => {
  return ({
    vendorDetail: state.profile,
    // products: state.vendor.products,
    isLoading: state.isLoading,
    modalErrors: state.modalErrors,
    modalLoading: state.modalLoading,
  });
}

const mapActionsToProps = {
  getProfile: vendorActions.getProfile,
  // fetchVendorProducts: vendorActions.fetchVendorProducts,
  // createProduct: vendorActions.createProduct,
  // deleteProduct: vendorActions.deleteProduct,
  // updateProduct: vendorActions.updateProduct,
  clearModalErrors: vendorActions.clearModalErrors,
};

export default connect(mapStateToProps, mapActionsToProps)(VendorProfile);
