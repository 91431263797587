import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import { register } from '../../network/api';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class SignupForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      referralCode: ''
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.href = '/shop';
    }
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      'register',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Register',
    };

    

    // const [formData, setFormData] = React.useState({
    //   firstName: '',
    //   lastName: '',
    //   email: '',
    //   phone: '',
    //   referralCode: ''
    // });

    
    const handleSubmit = (e) => {
      e.preventDefault();

      const body = {
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
        email: e.target.email.value,
        phoneNumber: e.target.phone.value,
        referralCode: e.target.referralCode.value
      };
      // Handle form submission here
      register(body).then(response => {
        if (response.httpStatusCode === 200) {
          // Handle successful registration, e.g. redirect or show success message
          // Redirect to login page
          window.location.href = '/login';
        } else {
        }
      }
      ).catch(error => {
      }
      );
      
    };
    
    // };

    return (
      <section
      {...props}
      className={outerClasses}
      >
      <div className="container" style={{ display: "flex",
    justifyContent: "center" }}>
      <div className={innerClasses} style={{ width:"400px", maxWidth:"80%", background:"rgba(255,255,255,1)", padding:"25px 20px", borderRadius:"20px"}}>
      <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
      {this.state.error && (
        <div style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}>
        {this.state.error}
        </div>
      )}
      <div className="tiles-wrap">
        <div className="tiles-item">
        <div className="tiles-item-inner">
        <form onSubmit={(e) => {
          e.preventDefault();
          this.setState({ loading: true, error: null });
          
          const body = {
          firstName: e.target.firstName.value,
          lastName: e.target.lastName.value,
          email: e.target.email.value,
          phoneNumber: e.target.phone.value,
          referralCode: e.target.referralCode.value,
          role: 'ROLE_USER'
          };

          register(body)
          .then(response => {
            if (response.httpStatusCode === 200) {
            window.location.href = '/login';
            } else {
            this.setState({ error: response.message, loading: false });
            }
          })
          .catch(error => {
            this.setState({ 
            error: error.message || 'Registration failed. Please try again.', 
            loading: false 
            });
          });
        }}>
        <fieldset disabled={this.state.loading}>
          <div className="mb-12">
          <Input
          name="firstName"
          label="First Name"
          placeholder="First Name" 
          type="text"
          labelHidden
          onChange={(e) => this.setState({ firstName: e.target.value })}
          required />
          </div>
          <div className="mb-12">
          <Input
          name="lastName"
          label="Last Name"
          placeholder="Last name" 
          type="text"
          labelHidden
          onChange={(e) => this.setState({ lastName: e.target.value })}
          required />
          </div>
          <div className="mb-12">
          <Input
          name="email"
          type="email"
          label="Email"
          placeholder="Email"
          labelHidden
          onChange={ (e) => this.setState({ email: e.target.value }) }
          required />
          </div>
          <div className="mb-12">
          <Input
          name="phone"
          type="tel"
          label="Phone Number"
          placeholder="Phone Number"
          labelHidden
          onChange={(e) => this.setState({ phone: e.target.value })}
          required />
          </div>
          <div className="mb-12">
          <Input
          name="referralCode"
          type="text"
          label="Referral Code (Optional)"
          placeholder="Enter referral code"
          labelHidden
          onChange={(e) => this.setState({ referralCode: e.target.value})} />
          </div>
          <div className="mt-24 mb-32">
          <Button color="primary" wide type="submit" disabled={this.state.loading}>
          {this.state.loading ? 'Signing up...' : 'Sign up'}
          </Button>
          </div>
        </fieldset>
        </form>
        <div className="signin-bottom has-top-divider">
        <div className="pt-32 text-xs center-content text-color-low">
          Already have an account? <Link to="/login/" className="func-link">Login</Link>
        </div>
        </div>
        </div>
        </div>
      </div>
      </div>
      </div>
      </section>
    );
  }
}

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;

export default SignupForm;