import React from 'react'
import Logo from '../../assets/images/arena-logo-white.svg';

const PageHeader = ({page}) => {

    return (
        <header className="page-banner">
                <div className='page-banner-inner'>
                    {/* <img src={Logo} style={{height:"50px"}} /> */}
                    <h4>{page}</h4>
                    <p className='breadcumb'>Home &gt; {page}</p>
                </div>
            </header>
    );
}

export default PageHeader;