import React, { useState } from 'react';
import Logo from '../assets/images/arena-logo-white.svg';
import filter from '../assets/images/filter.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import img1 from '../assets/images/craft.jpg';
import grid from '../assets/images/grid.svg';
import viewList from '../assets/images/view-list.svg';
import PageHeader from '../components/sections/PageHeader';
import Button from '../components/elements/Button';
import Products from '../components/sections/Products';
import { addNewOrder } from '../network/order_api';
import { or } from 'ajv/dist/compile/codegen';

const Product = ({prod}) => {

    const products = [
        { id: 1, image: img1, name: 'Image 1' },
        { id: 2, image: img1, name: 'Image 2' },
        { id: 3, image: img1, name: 'Image 3' },
        { id: 4, image: img1, name: 'Image 4' },
      ];
    //   get the product from local storage
    const product = JSON.parse(localStorage.getItem('selectedProduct'));
    
    const [quantity, setQuantity] = React.useState(1);
    // const [order_description, setDescription] = React.useState("");
    const [o_size, setSize] = React.useState("");
    const [o_color, setColor] = React.useState("");
    const [o_variant, setVariant] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const increaseQuantity = () => {
        setQuantity(prev => prev + 1);
    };

    const decreaseQuantity = () => {
        setQuantity(prev => prev > 1 ? prev - 1 : 1);
    };

    const [mainImage, setMainImage] = React.useState(product.imageUrl);

    const handleImageClick = (imageUrl) => {
        setMainImage(imageUrl);
    };

    const [showSlideshow, setShowSlideshow] = React.useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

    const allImages = [
        product.imageUrl,
        ...[product.image2, product.image3, product.image4, product.image5].filter(img => img)
    ];

    const nextSlide = () => {
        setCurrentSlideIndex((prev) => (prev + 1) % allImages.length);
    };

    const prevSlide = () => {
        setCurrentSlideIndex((prev) => (prev - 1 + allImages.length) % allImages.length);
    };

    const addToCart = async () => {
        setLoading(true);
        setError(null);
        try {
            // Check if user is logged in by looking for token in localStorage
            const token = localStorage.getItem('token');
            if (!token) {
            toast.error('Please login to add items to cart');
            setLoading(false);
            return;
            }

            const order_description = `${product.name} : ${o_size} ${o_color} ${o_variant}`;
            const response = await addNewOrder(product.user.id, "", order_description, product.id, quantity);
            
            toast.success('Product added to cart successfully!');
        } catch (error) {
            setError(error.message || 'Failed to add product to cart');
            toast.error(error.message || 'Failed to add product to cart');
        } finally {
            setLoading(false);
        }
    };
    return (
        <React.Fragment>
            <ToastContainer />
            {showSlideshow && (
                <div className="slideshow-overlay" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.9)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <button onClick={() => setShowSlideshow(false)} style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}>×</button>
                    <button onClick={prevSlide} style={{
                        position: 'absolute',
                        left: '20px',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}>←</button>
                    <img src={allImages[currentSlideIndex]} style={{
                        maxHeight: '90vh',
                        maxWidth: '90vw',
                        objectFit: 'contain'
                    }} />
                    <button onClick={nextSlide} style={{
                        position: 'absolute',
                        right: '20px',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}>→</button>
                </div>
            )}
            <div className='shop-band'>
                <section className='sb-left'>
                    <p style={{margin:0}}> Home &gt; Shop &gt; | {product.name}</p>
                </section>
            </div>
            <div className='product-details'>
                <section className='image-section'>
                    <div className='images-list'>
                        <ul>
                                <li onClick={() => handleImageClick(product.imageUrl)}>
                                    <img className='prod-image' src={product.imageUrl} />
                                </li>
                            {product.image2 && (
                                <li onClick={() => handleImageClick(product.image2)}>
                                    <img className='prod-image' src={product.image2} />
                                </li>
                            )}
                            {product.image3 && (
                                <li onClick={() => handleImageClick(product.image3)}>
                                    <img className='prod-image' src={product.image3} />
                                </li>
                            )}
                            {product.image4 && (
                                <li onClick={() => handleImageClick(product.image4)}>
                                    <img className='prod-image' src={product.image4} />
                                </li>
                            )}
                            {product.image5 && (
                                <li onClick={() => handleImageClick(product.image5)}>
                                    <img className='prod-image' src={product.image5} />
                                </li>
                            )}
                        </ul>
                    </div>
                    <div style={{display: "flex", flexDirection:"column", alignItems:"center", padding: 0, margin: 0}}>
                    <img 
                        src={mainImage} 
                        className='main-prod-image' 
                        onClick={() => {
                            setCurrentSlideIndex(allImages.indexOf(mainImage));
                            setShowSlideshow(true);
                        }}
                        style={{ cursor: 'pointer' }}
                    />

                    <div style={{display:"flex", justifyContent:"space-between", gap:"20px"}}>
                        <section className="quan-section">
                            <Button onClick={decreaseQuantity} className="button-primary">-</Button>
                            &nbsp;
                            {quantity}
                            &nbsp;
                            <Button onClick={increaseQuantity} className="button-primary">+</Button>
                        </section>
                        <Button onClick={addToCart} className={`button-primary add-to-cart-btn ${loading ? 'loading' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <span className="loader"></span>
                    ) : (
                        'Add to Cart'
                    )}</Button>
                    </div>
                    {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
                    </div>
                </section>
                <section className='desc-section'>
                    <div>
                        <h3 className='prod-name'>{product.name}</h3>
                        <p className='prod-price'>{product.price} <span><s>{product.priceAfterDiscount}</s></span></p>
                        <p className='prod-desc'>{product.description}</p>
                        <p className='prod-sizes' style={{marginBottom:"0px"}}>Sizes :</p>
                        <div className="sizes-container" style={{marginBottom:"20px"}}>
                            {product.size.split(',').map((size, index) => (
                                <span
                                    key={index}
                                    className={`size-box ${o_size.includes(size.trim()) ? 'selected' : ''}`}
                                    onClick={() => setSize(size.trim())}
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        margin: '0 5px',
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        backgroundColor: o_size.includes(size.trim()) ? '#f0f0f0' : 'white',
                                        position: 'relative'
                                    }}
                                >
                                    {o_size.includes(size.trim()) && (
                                        <span style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            right: '-8px',
                                            fontSize: '12px',
                                            color: 'green'
                                        }}>✓</span>
                                    )}
                                    {size.trim()}
                                </span>
                            ))}
                        </div>
                        <p className='prod-colors' style={{marginBottom:"0px"}}>Colors :</p>
                        <div className="colors-container" style={{marginBottom:"20px"}}>
                            {product.color.split(',').map((color, index) => (
                                <span
                                    key={index}
                                    className={`color-box ${o_color.includes(color.trim()) ? 'selected' : ''}`}
                                    onClick={() => setColor(color.trim())}
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        margin: '0 5px',
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        backgroundColor: color.trim().toLowerCase(),
                                        color: ['white', 'yellow', 'beige'].includes(color.trim().toLowerCase()) ? 'black' : 'white',
                                        position: 'relative'
                                    }}
                                >
                                    {o_color.includes(color.trim()) && (
                                        <span style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            right: '-8px',
                                            fontSize: '12px',
                                            color: 'white',
                                            textShadow: '0px 0px 2px black'
                                        }}>✓</span>
                                    )}
                                    {color.trim()}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between", gap:"20px"}}>
                        <section className="quan-section">
                            <Button onClick={decreaseQuantity} className="button-primary">-</Button>
                            &nbsp;
                            {quantity}
                            &nbsp;
                            <Button onClick={increaseQuantity} className="button-primary">+</Button>
                        </section>
                        <Button onClick={addToCart} className={`button-primary add-to-cart-btn ${loading ? 'loading' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <span className="loader"></span>
                    ) : (
                        'Add to Cart'
                    )}</Button>
                    </div>
                    {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
                    <hr/>
                    <div>
                        {/* <p>SKU : {product?.quantity || 'N/A'}</p> */}
                        <p>Category : {product?.category.value || 'Uncategorized'}</p>
                        <p>Tags : {product?.tags || 'No tags'}</p>
                    <div className="social-share">
                        <Button 
                            className="button-primary"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator.share({
                                        title: product.name,
                                        text: product.description,
                                        url: window.location.href,
                                    })
                                    .catch((error) => console.log('Error sharing:', error));
                                } else {
                                    navigator.clipboard.writeText(window.location.href)
                                    .then(() => toast.success('Link copied to clipboard!'))
                                    .catch(() => toast.error('Failed to copy link'));
                                }
                            }}
                        >
                            Share
                        </Button>
                    </div>
                    </div>
                    <div></div>
                </section>
            </div>
            {/* <Products className="illustration-section-01" products={products} /> */}
        </React.Fragment>
    );
}


export default Product;