import React, { useState } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './SideNavigation.scss';

const SideNavigation = props => {

 
  const sideNavLinks = {
    attendantView: [
      { navText: 'Make Sale', navLink: './make-sale' }, 
      { navText: 'My Sales', navLink: './my-sales' },
      { navText: 'Account', navLink: '/account' },
      // { navText: 'Products', navLink: '/products' },
      // { navText: 'Orders', navLink: '/orders' },
      // { navText: 'Payments', navLink: '/payments' },
      // { navText: 'Category', navLink: '/category' },
      // { navText: 'Settings', navLink: '/settings' },
      // { navText: 'Support', navLink: '/support' },
    ],
    adminView: [
      { navText: 'Dashboard', navLink: '/dashboard' },
      // { navText: 'Staff Accounts', navLink: './staff-accounts' },
      // { navText: 'Account', navLink: '/account' },
      // { navText: 'Vendors', navLink: '/vendors' },
      { navText: 'Products', navLink: '/products' },
      { navText: 'Orders', navLink: '/orders' },
      { navText: 'Payments', navLink: '/payments' },
      // { navText: 'Category', navLink: '/category' },
      { navText: 'Settings', navLink: '/settings' },
      { navText: 'Support', navLink: '/support' },
    ]
  };

  const settingsSubLinks = [
    { navText: 'Profile Settings', navLink: '/settings/profile' },
    { navText: 'Account Settings', navLink: '/settings/account' },
    { navText: 'Inventory Settings', navLink: '/settings/inventory' },
    { navText: 'Payment Settings', navLink: '/settings/payment' },
    { navText: 'Security & Privacy', navLink: '/settings/security' },
  ];

  // Use useState to toggle settings dropdown
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);

  const toggleSettings = () => {
    setIsSettingsExpanded(prevState => !prevState);
  };

  const {
    auth: { userRole }
  } = props;

  const mainNavLinks =
      userRole === 'Attendant'
        ? sideNavLinks.attendantView
        : sideNavLinks.adminView;

  const navLinks =
    userRole === 'Attendant'
      ? sideNavLinks.attendantView.map((link, index) => {
          return (
            <li key={index}>
              <NavLink to={link.navLink.slice(1)} activeClassName="selected">
                {link.navText}
              </NavLink>
            </li>
          );
        })
      : sideNavLinks.adminView.map((link, index) => {
          return (
            <li key={index}>
              <NavLink to={link.navLink.slice(1)} activeClassName="selected">
                {link.navText}
              </NavLink>
            </li>
          );
        });
    

  return (
    <aside className="aside">
      <nav className="aside__nav admin">
        {/* <ul>{navLinks}</ul> */}
        <ul>
            {mainNavLinks.map((link, index) => (
              <li key={index} >
                {/* <a onClick={() => { return <Navigate to={link.navLink} />; }} activeClassName="selected">

                  {link.navText}
                </a> */}
                {/* </Navigate> */}
                {/* <NavLink onClick={() => Navigate(link.navLink)} to ={"#"} activeClassName="selected"> */}
                {/* </NavLink> */}
                <NavLink to={"../"+ link.navLink.slice(1)} activeClassName="selected">
                  {link.navText}
                </NavLink>
              </li>
            ))}

            {/* Settings Dropdown */}
            {/* {userRole === 'Admin' && (
              <li>
                <div className="dropdown-header" onClick={toggleSettings}>
                  Settings
                </div>
                {isSettingsExpanded && (
                  <ul className="dropdown">
                    {settingsSubLinks.map((link, index) => (
                      <li key={index} className="dropdown-item">
                        <NavLink to={link.navLink.slice(1)} activeClassName="selected">
                          {link.navText}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )} */}
          </ul>
      </nav>
    </aside>
  );
};

SideNavigation.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired
};

const mapStateToProp = state => ({
  auth: state.auth
});

export default connect(mapStateToProp)(SideNavigation);
