import React from "react";
import PageHeader from "../components/sections/PageHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import '../assets/scss/core/layout/_contact.scss';
import Button from "../components/elements/Button";

const Contact = () => {
    return (
        <React.Fragment>
            <PageHeader page="Contact"></PageHeader>
            <div className="contact-container">
                <div className="contact-form">
                    <h2>Get In Touch With Us</h2>
                    <p>For More Information About Our Product & Services. Please Feel Free To Drop Us An Email.</p>
                    
                    <form>
                        <div className="form-group">
                            <label>Your Name</label>
                            <input type="text" placeholder="Enter your name" />
                        </div>
                        
                        <div className="form-group">
                            <label>Email Address</label>
                            <input type="email" placeholder="Enter your email" />
                        </div>
                        
                        <div className="form-group">
                            <label>Subject</label>
                            <input type="text" placeholder="This is optional" />
                        </div>
                        
                        <div className="form-group">
                            <label>Message</label>
                            <textarea placeholder="Hi! i'd like to ask about"></textarea>
                        </div>
                        
                        <Button className="button-primary" type="submit">Submit</Button>
                    </form>
                </div>
                
                <div className="contact-info">
                    
                    <div className="info-card">
                        <FontAwesomeIcon icon={faPhone} className="icon" />
                        <h3>Phone</h3>
                        <p>Mobile: +(234) 904-444-4157</p>
                        <p>WhatsApp: +(234) 904-444-4157</p>
                    </div>
                    
                    <div className="info-card">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                        <h3>Email</h3>
                        <p>hello@thearenang.com</p>
                    </div>
                    <div className="info-card">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                        <h3>Address</h3>
                        <p>House 2, Road 3 Biodun Adeyemo Crescent, Olorunda Road, off Akobo, isokan Estate</p>
                    </div>
                    
                    <div className="info-card">
                        <FontAwesomeIcon icon={faClock} className="icon" />
                        <h3>Working Time</h3>
                        <p>Monday-Friday: 9:00 - 18:00</p>
                        <p>Saturday-Sunday: 10:00 - 18:00</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Contact;