import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as productActions from '../../actions/productActions/productActions';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import Util from '../../utils';
import CreateProductModal from '../Modals/CreateProductModal';
import * as categoryActions from '../../actions/categoryActions/categoryActions';
// import ToastContainer from '../Toasts/ToastContainer';
import UpdateProductModal from '../Modals/UpdateProductModal';
import DeleteProductModal from '../Modals/DeleteProductModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewProductModal from '../Modals/ViewProductModal';

class ProductsPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productData: {},
      setCreateModalOpen: false,
      updateModalIsOpen: false,
      deleteModalIsOpen: false,
      viewModalIsOpen: false,
    };
  }

  componentDidMount() {
    const { getProducts } = this.props;
    getProducts();
  }

  openViewModal = productData => {
    this.setState({ viewModalIsOpen: true, productData });
  };

  openUpdateModal = productData => {
    this.setState({ updateModalIsOpen: true, productData });
  };

  openDeleteModal = productData => {
    this.setState({ deleteModalIsOpen: true, productData });
  };

  openModal = () => {
    this.setState({ setCreateModalOpen: true });
  };

  closeModal = () => {
    // const {
    //   clearModalErrors,
    //   products: { modalErrors }
    // } = this.props;
    const { clearModalErrors } = this.props;
    
    // this.setState({ setCreateModalOpen: false, updateModalIsOpen: false, deleteModalIsOpen: false });
    this.setState({
      setCreateModalOpen: false,
      updateModalIsOpen: false,
      deleteModalIsOpen: false,
      productData: {}, // Reset product data if any
      viewModalIsOpen: false,
      selectedProduct: null // Reset selected product if any
    });
    // if (modalErrors.length) clearModalErrors();

     // Clear any form errors
     if (this.props.products.modalErrors.length) {
      clearModalErrors();
    }
  };

  // createProduct = productData => {
  //   const { createProduct } = this.props;
  //   createProduct(productData);
  //   this.closeModal();
  // };
  
  createProduct = async productData => {
    // const loadingToast = toast.loading('Creating product...') || 'loading-toast';
    toast.loading('Creating product...');
    try {
      const { createProduct } = this.props;
      await createProduct(productData);
      toast.dismiss();
      this.closeModal();
      toast.success('Product created successfully');
    } catch (err) {
      toast.dismiss();
      toast.error('Failed to create product');
      throw err;
    }
  };

  updateProduct = productData => {
    const { updateProduct } = this.props;
    updateProduct(productData.id, productData);
    this.closeModal();
  }
  

  render() {
    const {
      products,
      categories,
      products: { productsList, isLoading, modalErrors, modalLoading, message: actionMessage },
      createProduct,
      deleteProduct,
      updateProduct,
      clearModalErrors,
      getCategories
    } = this.props;


    const { setCreateModalOpen, productData, updateModalIsOpen, deleteModalIsOpen, viewModalIsOpen } = this.state;

    if (isLoading) {
      return <Loading title="Fectching Products" />;
    }
    return (
      <section className="main">
        <ToastContainer />
        <section className="sales">
          <button type="button" className="btn btn--gradient" id="create-product" onClick={this.openModal}>
            Create Product
          </button>

          <CreateProductModal
            modalOpenState={setCreateModalOpen}
            clearModalErrors={clearModalErrors}
            closeModal={this.closeModal}
            modalErrors={modalErrors}
            modalLoading={modalLoading}
            // createProduct={createProduct}
            getCategories={getCategories}
            categories={categories}
            createProduct={this.createProduct}
          />

          <ToastContainer message={actionMessage} />

          {/* <section className="filters">
            <form id="filter-name" className="filters__form">
              <fieldset>
                <legend>Filter By Product Name</legend>
                <input id="search-name" type="text" placeholder="Search Product" />
                <input type="submit" value="Retrieve Records" />
              </fieldset>
            </form>
            <form id="filter-rows" className="filters__form">
              <fieldset>
                <legend>Filter Rows</legend>
                <select id="filter-pref">
                  <option value="10">10 Records</option>
                  <option value="50">50 Records</option>
                  <option value="100">100 Records</option>
                </select>
                <input type="submit" value="Retrieve Records" />
              </fieldset>
            </form>
            <form id="filter-qty" className="filters__form">
              <fieldset>
                <legend>Filter Remaining Quantity</legend>
                <select id="qty-pref">
                  <option value="10">Less than 10</option>
                  <option value="20">Less than 20</option>
                  <option value="30">Less than 30</option>
                </select>
                <input type="submit" value="Retrieve Records" />
              </fieldset>
            </form>
            <button type="button" id="clear-product-filters" className="filters__clear">
              Clear Filters
            </button>
          </section> */}
          <div className="table-wrapper">
            <table id="products-table" className="table">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productsList.length ? (
                  productsList.map(product => {
                    return (
                      <tr key={product.product_id}>
                      <td>{productsList.indexOf(product) + 1}</td>
                      <td style={{
                        display: 'flex', alignItems: 'center', gap: '20px',
                        cursor: 'pointer' // Add cursor pointer to indicate clickable
                      }} 
                      onClick={() => this.openViewModal(product)} // Make the whole cell clickable
                      >
                        <img 
                        src={product.imageUrl} 
                        alt={product.name}
                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                        />
                        {product.name}</td>
                      <td>{Util.formatPrice(product.price)}</td>
                      <td>
                        {product.quantity ? (
                        product.quantity
                        ) : (
                        <span style={{ background: 'antiquewhite', padding: '0 2px' }}>Out of stock</span>
                        )}
                      </td>
                      <td data-id={product.product_id}>
                        <button onClick={() => this.openUpdateModal(product)} type="button" className="blue">
                        Update
                        </button>
                        <button onClick={() => this.openDeleteModal(product)} type="button" className="red">
                        Delete
                        </button>
                      </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">You have not created products yet.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
        {productsList.length ? <Pagination entity={products} allProps={this.props} /> : null}

        <UpdateProductModal
            modalState={updateModalIsOpen}
            product={productData}
            closeModal={this.closeModal}
            updateproduct={updateProduct}
            modalErrors={modalErrors}
            clearModalErrors={clearModalErrors}
            modalLoading={modalLoading}
            categories={categories}
          />
          <DeleteProductModal
            modalState={deleteModalIsOpen}
            product={productData}
            closeModal={this.closeModal}
            deleteProduct={deleteProduct}
            modalErrors={modalErrors}
            clearModalErrors={clearModalErrors}
            modalLoading={modalLoading}
          />

          <ViewProductModal
            product={productData}
            isOpen={viewModalIsOpen}
            onClose={this.closeModal}
          />
      </section>
    );
  }
}

ProductsPane.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  clearModalErrors: PropTypes.func.isRequired,
  products: PropTypes.oneOfType([PropTypes.object]).isRequired,
  categories: PropTypes.oneOfType([PropTypes.object]).isRequired
};

const mapStateToProps = state => ({ products: state.products, categories: state.categories });

const mapActionsToProps = {
  getProducts: productActions.getProducts,
  goToNextPage: productActions.goToNextPage,
  goToPrevPage: productActions.goToPrevPage,
  createProduct: productActions.createProduct,
  updateProduct: productActions.updateProduct,
  deleteProduct: productActions.deleteProduct,
  clearModalErrors: productActions.clearModalErrors,
  getCategories: categoryActions.getCategories
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ProductsPane);
