import axios from 'axios';

class DeliveryAPI {
    constructor() {
        this.token = "ssk_e91efc835b659fc0e05fb1d34ceb2b1bcc3ef0b15fd639cb5cd0c86abba3d4aa";
        this.baseURL = 'https://delivery.apiideraos.com/api/v2/token/';
        // this.baseURL = 'https://delivery-staging.apiideraos.com/api/v2/token/';
        
        this.axiosInstance = axios.create({
            baseURL: this.baseURL,
            timeout: 60000,
        });
    }

    setAuthHeader(authenticate = true) {
        if (authenticate) {
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        } else {
            delete this.axiosInstance.defaults.headers.common['Authorization'];
        }
    }

    async isDeviceConnected() {
        try {
            await fetch('https://example.com');
            return true;
        } catch (error) {
            return false;
        }
    }

    handleError = async (error) => {
        let message = "";
        
        if (error.response?.data?.message) {
            message = error.response.data.message;
        } else {
            switch (error.code) {
                case 'ERR_NETWORK':
                    message = "Timeout Error: The connection or operation timed out. Please check your internet connection and try again later.";
                    break;
                case 'ECONNABORTED':
                    message = "Connection Timeout: Unable to establish a connection.";
                    break;
                default:
                    const deviceConnected = await this.isDeviceConnected();
                    message = deviceConnected 
                        ? "Unknown Error: An error has occurred. Please try again later or contact support for assistance." 
                        : "No Network Connection";
            }
        }
        return message;
    }

    async getAllRates(data) {
        try {
            this.setAuthHeader(true);
            const response = await this.axiosInstance.post('tariffs/allprice', data);
            
            return response;
        } catch (error) {
            const errorMessage = await this.handleError(error);
            return error.response || { data: null, status: 500, message: errorMessage };
        }
    }

    async bookShipment(data) {
        try {
            this.setAuthHeader(true);
            const response = await this.axiosInstance.post('bookshipment', data);
            
            return response;
        } catch (error) {
            const errorMessage = await this.handleError(error);
            return error.response || { data: null, status: 500, message: errorMessage };
        }
    }

    async get(url, queryParams = {}, authenticate = true) {
        try {
            this.setAuthHeader(authenticate);
            const response = await this.axiosInstance.get(url, { params: queryParams });
            return response;
        } catch (error) {
            const errorMessage = await this.handleError(error);
            return error.response || { data: null, status: 500, message: errorMessage };
        }
    }

    async post(url, data = {}, authenticate = true) {
        try {
            this.setAuthHeader(authenticate);
            const response = await this.axiosInstance.post(url, data);
            return response;
        } catch (error) {
            const errorMessage = await this.handleError(error);
            return error.response || { data: null, status: 500, message: errorMessage };
        }
    }
}

export const deliveryAPI = new DeliveryAPI();
