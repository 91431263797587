import React from 'react';
import Helmet from 'react-helmet';
import Main from '../../components/Main/Main';
import DefaultForgotForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <>
    <Helmet>
      <title>Forgot Password - Arena Storefront</title>
    </Helmet>
    <Main>
      <DefaultForgotForm />
    </Main>
  </>
);

export default ForgotPasswordPage;
