import React, { Component } from 'react';
import Modal from 'react-modal';
import ErrorToast from '../ErrorToast/ErrorToast';
import Spinner from '../Spinner/Spinner';

class UpdateProductModal extends Component {
  componentDidMount = () => {};

  handleproductUpdate = e => {
    e.preventDefault();
    const { updateproduct, clearModalErrors, modalErrors, closeModal } = this.props;
    const formId = e.target.dataset.id;
    if (modalErrors.length) clearModalErrors();

    // const productInfo = {
    //   id: e.target.dataset.id,
    //   name: this.name.value,s
    //   price: this.price.value,
    //   discount: this.discount.value,
    //   availability: this.availability.value,
    //   description: this.description.value,

    // };

    const formData = new FormData();
    const user = JSON.parse(localStorage.getItem('userInfo')) || '';
    // Collect all form data
    formData.append('userId', user.id);
    formData.append('name', this.name.value);
    formData.append('price', this.price.value);
    formData.append('dicountpercent', this.discount.value);
    // formData.append('availability', this.availability.value);
    formData.append('description', this.description.value);
    formData.append('story', this.story.value);
    formData.append('size', this.size.value);
    formData.append('color', this.color.value);
    formData.append('categoryId', this.category.value);
    formData.append('weight', this.weight.value);
    formData.append('height', this.height.value);
    formData.append('width', this.width.value);
    formData.append('quantity', this.quantity.value);
    formData.append('imageUrl', this.image.files[0]);


    // if (!productInfo.password) delete productInfo.password;
    // if (productInfo.role === 'Owner') delete productInfo.role;

    updateproduct(formId, formData, closeModal).then(() => {
      // closeModal();
      window.location.reload();
    });
  };

  render() {
    const { modalState, product, closeModal, modalErrors, modalLoading, categories: { allCategories } } = this.props;
    return (
      <Modal
      isOpen={modalState}
      onRequestClose={closeModal}
      shouldCloseOnEsc
      ariaHideApp={false}
      contentLabel="Example Modal"
      overlayClassName="modal-overlay"
      className={`modal ${modalState ? 'is-active' : ''}`}
      >
      <div className="form-body">
        <h3>Update Product Information</h3>
        <span role="button" tabIndex={0} className="form__closeBtn" onKeyUp={() => {}} onClick={closeModal}>
        &times;
        </span>
        <form id="update-product-form" data-id={product.id} onSubmit={this.handleproductUpdate} encType="multipart/form-data">
        {modalErrors.length ? <ErrorToast errors={modalErrors} /> : null}
        
        <div className="form-sides">
          {/* Left Side */}
          <div className="form-side">
          <div className="input-group"></div>
            <label htmlFor="update-name">Product Name <sup>*</sup></label>
            <input
            ref={el => (this.name = el)}
            id="update-name"
            type="text"
            placeholder="Enter product name"
            defaultValue={product.name}
            required
            />
          </div>

          <div className="input-group">
            <label htmlFor="update-price">Product Price</label>
            <input
            ref={el => (this.price = el)}
            id="update-price"
            type="number"
            step="0.01"
            placeholder="Enter price"
            defaultValue={product.price}
            required
            />
          </div>

          <div className="input-group">
            <label htmlFor="update-discount">Discount (%)</label>
            <input
            ref={el => (this.discount = el)}
            id="update-discount"
            type="number"
            step="0.01"
            placeholder="Enter discount"
            defaultValue={product.dicountpercent}
            />
          </div>

          {/* <div className="input-group">
            <label htmlFor="update-availability">Availability</label>
            <select
            ref={el => (this.availability = el)}
            id="update-availability"
            defaultValue={product.availability}
            required
            >
            <option value="">Select availability</option>
            <option value="In stock">In stock</option>
            <option value="Out of stock">Out of stock</option>
            </select>
          </div> */}

          <div className="input-group">
            <label htmlFor="update-description">Description</label>
            <textarea
            ref={el => (this.description = el)}
            id="update-description"
            placeholder="Enter description"
            rows="4"
            defaultValue={product.description}
            required
            ></textarea>
          </div>

          <div className="input-group">
            <label htmlFor="update-story">Story Behind it</label>
            <textarea
            ref={el => (this.story = el)}
            id="update-story"
            placeholder="Enter story"
            rows="4"
            defaultValue={product.story}
            ></textarea>
          </div>
          </div>

          {/* Middle Side */}
          <div className="form-side">
          <div className="form-sides">
            <div className="form-side input-group" style={{minWidth: "50px", maxWidth: "50%"}}>
            <label htmlFor="update-size">Sizes</label>
            <input
              ref={el => (this.size = el)}
              id="update-size"
              type="text"
              placeholder="Enter size"
              defaultValue={product.size}
            />
            </div>

            <div className="form-side input-group" style={{minWidth: "50px", maxWidth: "50%"}}>
            <label htmlFor="update-color">Colors</label>
            <input
              ref={el => (this.color = el)}
              id="update-color"
              type="text"
              placeholder="Enter color"
              defaultValue={product.color}
            />
            </div>
          </div>

          <div className="input-group">
            <label htmlFor="update-category">Category</label>
            <select
            ref={el => (this.category = el)}
            id="update-category"
            defaultValue={product.category != null ? product.category.id : ''}
            required
            >
            <option value="">Select a category</option>
            {allCategories.map(category => (
              <option key={category.id} value={category.id}>
              {category.value}
              </option>
            ))}
            </select>
          </div>

          <div className="form-sides">
            <div className="form-side input-group" style={{minWidth: "50px", maxWidth: "50%"}}>
            <label htmlFor="update-weight">Weight (kg)</label>
            <input
              ref={el => (this.weight = el)}
              id="update-weight"
              type="number"
              step="0.01"
              defaultValue={product.weight}
            />
            </div>

            <div className="form-side input-group" style={{minWidth: "50px", maxWidth: "50%"}}>
            <label htmlFor="update-height">Height (cm)</label>
            <input
              ref={el => (this.height = el)}
              id="update-height"
              type="number"
              step="0.01"
              defaultValue={product.height}
            />
            </div>
          </div>

          <div className="form-sides">
            <div className="form-side input-group" style={{minWidth: "50px", maxWidth: "50%"}}>
            <label htmlFor="update-width">Width (cm)</label>
            <input
              ref={el => (this.width = el)}
              id="update-width"
              type="number"
              step="0.01"
              defaultValue={product.width}
            />
            </div>

            <div className="form-side input-group" style={{minWidth: "50px", maxWidth: "50%"}}>
            <label htmlFor="update-quantity">Quantity</label>
            <input
            ref={el => (this.quantity = el)}
            id="update-quantity"
            type="number"
            defaultValue={product.quantity}
            required
            />
          </div>
          </div>
          </div>

          {/* Right Side */}
          <div className="form-side">
          <div className="input-group">
            <label htmlFor="update-image">Product Image</label>
            <input
            ref={el => (this.image = el)}
            id="update-image"
            type="file"
            accept="image/*"
            />
          </div>

          



          </div>

          <div className="input-group">
              <button type="submit" disabled={modalLoading} className="btn btn--gradient full-width">
                {modalLoading ? <Spinner /> : 'Update Product'}
              </button>
          </div>
        </form>
      </div>
      </Modal>
    )

  }
}

export default UpdateProductModal;
