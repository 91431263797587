import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions/authActions';
import './VerifyOtp.scss';
import { Navigate } from 'react-router-dom';
import ErrorToast from '../ErrorToast/ErrorToast';
import Spinner from '../Spinner/Spinner';
import logo from '../../assets/images/arena-logo-normal-svg.svg';

export class VerifyOtp extends Component {
  handleVerifyOtp = e => {
    e.preventDefault();
    const { verifyOtp, auth: { loginErrors },
    clearLoginErrors } = this.props;
    if (loginErrors.length) clearLoginErrors();

    const email = localStorage.getItem('userMail');
    const otp = this.otp.value;
    const deviceId = "";
    verifyOtp({ email, otp, deviceId });
  };

  handleResend = () => {
    const { resendOtp, auth: { loginErrors },
    clearLoginErrors } = this.props;
    const email = localStorage.getItem('userMail');
    const deviceId = "";
    resendOtp({ email, deviceId });
  };

  render() {
    const {
      auth: { otpVerified, user, isLoading, loginErrors }
    } = this.props;
    if (otpVerified) {
      return <Navigate to="/business/set-password" />;
    }

    return (
      <section className="form__wrapper">
        <div className="form">
        <img className="form__logo" src={logo} alt="logo" />
          <h4 className="form__appname">Store Manager</h4>
          <h4 className="form__header">Verify OTP</h4>
          <p className="form__description">
          Enter the OTP sent to your email.</p>
          <form className="form__login" onSubmit={this.handleVerifyOtp}>
          {loginErrors.length ? <ErrorToast errors={loginErrors} /> : null}
            <div className="form__control">
              <input
              autoFocus
              id="login-email"
              className="form__input"
                type="text"
                placeholder="Enter OTP"
                ref={otp => (this.otp = otp)}
                required
              />
            </div>
            <div className="form__control">
              <button type="submit" className="btn btn--gradient full-width">
              {isLoading ? <Spinner /> :'Verify' }</button>
            </div>
            <div className="form__control">
  <a type="button" style={{ background:"none", border:"none",}} onClick={this.handleResend}>Didn't get the code ? Resend</a>
</div>
          </form>
        </div>
      </section>
    );
  }
}

VerifyOtp.propTypes = {
  verifyOtp: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    otpVerified: PropTypes.bool,
    user: PropTypes.string,
    isLoading: PropTypes.bool,
    loginErrors: PropTypes.array
  }).isRequired,
  clearLoginErrors: PropTypes.func.isRequired
};

const mapStateToProp = state => ({
  auth: state.auth
});

const mapActionsToProps = { verifyOtp: authActions.verifyOtp, resendOtp:authActions.resendOtp, clearLoginErrors: authActions.clearLoginErrors };

export default connect(mapStateToProp, mapActionsToProps)(VerifyOtp);