import React from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import notFoundIcon from '../../assets/img/404.png';
import './NotFound.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="notfound">
      <Helmet>
        <title>404 - Page Not Found | Arena</title>
      </Helmet>
      
      <img 
        className="notfound__image" 
        src={notFoundIcon} 
        alt="404 illustration" 
      />
      
      <h1 className="notfound__message">
        Oops! Page not found
      </h1>
      
      <p className="notfound__submessage">
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
      </p>
      
      <button 
        type="button" 
        className="notfound__button"
        onClick={() => navigate('/')}
      >
        Back to Home
      </button>
    </div>
  );
};

export default NotFound;
