import { applyMiddleware, createStore } from 'redux';

import {thunk} from 'redux-thunk';
import rootReducer from '../reducers';


const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || ((f) => f);


// const allStoreEnhancers = composeWithDevTools(applyMiddleware(thunk));
const allStoreEnhancers = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, allStoreEnhancers);

export default store;
