
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const ShopperForm = (props) => {
  const [frmLoad, setFrmLoad] = useState(false);

  const mailing = () => {
    const form = document.getElementById('myForm');
    const showAlertInput = document.getElementById('showAlert');

    form.addEventListener('submit', (event) => {
      event.preventDefault(); // Prevent default form submission
      setFrmLoad(true);

      fetch('https://script.google.com/macros/s/AKfycbxnet-GIE4eFk_w-25WeVIkRZUA-vUouUY_AKHRdJN2YDcUulbA5IahJzNuxxlAs8IW/exec', {
        method: 'POST',
        body: new FormData(form)
      })
      .then(response => response.json())
      .then(data => {
        setFrmLoad(false);
        showAlertInput.value = 'true';
        if (data.result === 'success' && showAlertInput) {
          alert('Your information was submitted successfully!');
          showAlertInput.value = 'false'; // Reset flag for next submission
        } else {
          // Handle errors (optional)
          alert('There was an error submitting your information.');
        }
      })
      .catch(error => {// Log errors for debugging
        alert('An unexpected error occurred.');
      });
    });
  };

  const outerClasses = classNames(
    'signin section',
    props.topOuterDivider && 'has-top-divider',
    props.bottomOuterDivider && 'has-bottom-divider',
    props.hasBgColor && 'has-bg-color',
    props.invertColor && 'invert-color',
    props.className
  );

  const innerClasses = classNames(
    'signin-inner section-inner',
    props.topDivider && 'has-top-divider',
    props.bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Are you looking for unique hand-made products and vintage items for your personal collections?',
  };

  return (
    <section
      {...props}
      className={outerClasses} id='waitlist'
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag="h3" data={sectionHeader} className="center-content" />
          <p className="center-content">Fill the form below to join our waitlist!</p>
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form id='myForm' >
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        name="name"
                        label="Full name"
                        placeholder="Full name" 
                        labelHidden
                        required />
                    </div>
                    <div className="mb-12">
                      <Input
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Email"
                        labelHidden
                        required />
                    </div>
                    <div className="mb-12">
                      <Input
                        name="phone"
                        type="phone"
                        label="Phone Number"
                        placeholder="Phone Number"
                        labelHidden
                        required />
                    </div>
                    <input type="hidden" id="showAlert" value="false"></input>
                    <div className="mt-24 mb-32">
                      <Button color="primary" wide onClick={mailing} loading={frmLoad}  >Submit</Button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopperForm;
