import axios from 'axios';

export const apiInstance = axios.create({
  // baseURL: 'https://mystoremanager.herokuapp.com/api/v1'
  baseURL: 'https://api.thearenang.com:8081/api/v1'
  // baseURL: 'http://127.0.0.1:8081/api/v1'
});

export default class Util {
  static makeRequest(url, options = { method: 'GET' }) {
    const PUBLIC_URLS = [
      "/user/auth/login",
      "/user/auth/loginPassword",
      "/user/auth/set-password",
      "/payments/webhook",
      "/user/auth/verify-otp",
      "/user/auth/verify-email",
      "/user/auth/verify-phone",
      "/user/auth/send-otp",
      "/user/auth/register",
      "/user/auth/reset-password/**"
    ];

    const isPublicUrl = PUBLIC_URLS.some(publicUrl => {
        const regex = new RegExp(publicUrl.replace('**', '.*'));
        return regex.test(url);
    });

    const headers = isPublicUrl ? {} : {
        Authorization: `Bearer ${localStorage.getItem('user')}`
    };

    return apiInstance({
        url,
        method: options.method,
        data: options.body,
        headers
    }).then(response => {
        if (!response) throw new Error('No response received');
        return response.data || response;
    }).catch(error => {
        throw error.response?.data || error;
    });
  }

  static formatCurrency(num) {
    if (num >= 1000000000) return `${(num / 1000000000).toFixed(2).replace(/\.0$/, '')}G`;
    if (num >= 1000000) return `${(num / 1000000).toFixed(2).replace(/\.0$/, '')}M`;
    if (num >= 1000) return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
    if (num < 1000) return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(num);
    return num;
  }

  static formatDate(date) {
    return new Date(date)
      .toJSON()
      .slice(2, 10)
      .split('-')
      .reverse()
      .join('/');
  }

  static formatPrice = number =>
    new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
}
