// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5px 10px; /* Equal spacing */
  padding: 10px;
  animation: slide 5s infinite linear;
}

.gallery-item {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  animation: move 8s infinite alternate;
}

/* Smooth hover zoom effect */
.gallery-item:hover {
  transform: scale(1.05);
}

/* Keyframes for moving effect */
@keyframes move {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/core/elements/_gallery.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2DAAA;EACA,kBAAA,EAAA,kBAAA;EACA,aAAA;EACA,mCAAA;AACJ;;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,+BAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,qCAAA;AACJ;;AAEE,6BAAA;AACA;EACE,sBAAA;AACJ;;AAEE,gCAAA;AACA;EACE;IACE,wBAAA;EACJ;EACE;IACE,4BAAA;EACJ;AACF;AAEE;EACE;IACE,wBAAA;EAAJ;EAEE;IACE,4BAAA;EAAJ;AACF","sourcesContent":[".gallery {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n    grid-gap: 5px 10px; /* Equal spacing */\n    padding: 10px;\n    animation: slide 5s infinite linear;\n  }\n  \n  .gallery-item {\n    overflow: hidden;\n    position: relative;\n    transition: transform 0.3s ease;\n  }\n  \n  .gallery-item img {\n    width: 100%;\n    height: auto;\n    display: block;\n    object-fit: cover;\n    animation: move 8s infinite alternate;\n  }\n  \n  /* Smooth hover zoom effect */\n  .gallery-item:hover {\n    transform: scale(1.05);\n  }\n  \n  /* Keyframes for moving effect */\n  @keyframes move {\n    from {\n      transform: translateY(0);\n    }\n    to {\n      transform: translateY(-20px);\n    }\n  }\n  \n  @keyframes slide {\n    0% {\n      transform: translateX(0);\n    }\n    100% {\n      transform: translateX(-10px);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
