import Util from '../../utils';
import { logOutUser } from '../authActions/authActions';

export const PROFILE_ACTION_START = 'PROFILE_ACTION_START';
export const PROFILE_ACTION_FAILURE = 'PROFILE_ACTION_FAILURE';
export const CLEAR_MODAL_ERRORS = 'CLEAR_MODAL_ERRORS';
export const GET_PROFILE = 'GET_PROFILE_SUCCESS';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
// export const GET_PROFILE_NEXT_PAGE = 'GET_PROFILE_NEXT_PAGE';
// export const GET_PROFILE_PREV_PAGE = 'GET_PROFILE_PREV_PAGE';

export const getProfileAction = payload => ({ type: PROFILE_ACTION_START, payload });

export const handleProfileActionFailure = response => ({
  type: PROFILE_ACTION_FAILURE,
  // payload: response.data.error ? [...response.data.error] : [response.data.message]
  payload: response.error ? [...response.error] : [response.message]
});

export const getProfile = () => async dispatch => {
  try {
    dispatch(getProfileAction({ isLoading: true }));

    // const { data, meta, message } = await Util.makeRequest('/products');
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const data = await Util.makeRequest('/business/' + user.businessId);
    
    dispatch({ type: GET_PROFILE, payload: { vendor: data.message, meta:{}, isLoading: false, message:"" } });
  } catch (error) {
    const { response, message } = error;
    if (error.httpStatusCode === 401) {
      dispatch(logOutUser());
    }
  }
};

export const updateProfile = (profileInfo, closeModal) => async dispatch => {
  try {
    dispatch(getProfileAction({ modalLoading: true }));
    const user = JSON.parse(localStorage.getItem('userInfo'));
    // profileInfo.businessId = user.businessId;
    const { data, message } = await Util.makeRequest('/business/update', { method: 'POST', body: profileInfo });
    
    dispatch({ type: CREATE_PROFILE_SUCCESS, payload: { vendor: data, message } });
    return closeModal();
  } catch (error) {
    const { response, message } = error;
    dispatch(getProfileAction({ modalLoading: false }));
    if (error.httpStatusCode === 401) return dispatch(logOutUser());
    return dispatch(handleProfileActionFailure(message));
  }
}

// export const goToNextPage = nextPage => async dispatch => {
//   try {
//     const { data, meta } = await Util.makeRequest(`/products?page=${nextPage}`);
//     dispatch({ type: GET_PROFILE_NEXT_PAGE, payload: { productsList: data, meta } });
//   } catch (error) {
//     const { response } = error;
//     if (response.status === 401) {
//       dispatch(logOutUser());
//     }
//   }
// };

// export const goToPrevPage = prevPage => async dispatch => {
//   try {
//     const { data, meta } = await Util.makeRequest(`/products?page=${prevPage}`);
//     dispatch({ type: GET_PROFILE_PREV_PAGE, payload: { productsList: data, meta } });
//   } catch (error) {
//     const { response } = error;
//     if (response.status === 401) {
//       dispatch(logOutUser());
//     }
//   }
// };

// export const createProduct = (productInfo, closeModal) => async dispatch => {
//   try {
//     dispatch(getProductsAction({ modalLoading: true }));
//     const { data, message } = await Util.makeRequest('/products', { method: 'POST', body: productInfo });
//     dispatch({ type: CREATE_PROFILE_SUCCESS, payload: { productsList: data, message } });
//     return closeModal();
//   } catch (error) {
//     const { response } = error;
//     dispatch(getProductsAction({ modalLoading: false }));
//     if (response.status === 401) return dispatch(logOutUser());
//     return dispatch(handleProductActionFailure(response));
//   }
// };

export const clearModalErrors = () => ({ type: CLEAR_MODAL_ERRORS });
