import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import HeroSlider from '../components/sections/HeroSlider';
import '../App.css';
import ShopperForm from '../components/sections/ShopperForm';
import img1 from '../assets/images/african_bg.jpg';
import next from '../assets/images/next_arrow.svg';
import img2 from '../assets/images/splash-screen-2.png';
import classNames from 'classnames';
import Image from '../components/elements/Image';
import About from '../components/sections/about';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Input from '../components/elements/Input';
import '../assets/scss/core/elements/_gallery.scss';
import Products from '../components/sections/Products';
import { getAllProducts } from '../network/product_api';
import art from '../assets/images/art.jpg'
import art2 from '../assets/images/art2.jpg'
import art3 from '../assets/images/art3.jpg'
import art4 from '../assets/images/art4.jpg'


class Home extends React.Component {
  state = {
    demoModalActive: false,
      // ...existing code...
      products: [],
      loading: true,
      error: null
    }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      artworks: [
        {
          id: 1,
          img: art,
          category: "Art",
          title: "Artwork 1",
        },
        {
          id: 3,
          img: art2,
          category: "Art",
          title: "Artwork 3",
        },
        {
          id: 4,
          img: art3,
          category: "Art",
          title: "Artwork 4",
        },
        {
          id: 5,
          img: art4,
          category: "Art",
          title: "Artwork 5",
        },
      ], // Example list of artworks
    };

    this.autoSlideInterval = null;
  }

  componentDidMount() {
    this.startAutoSlide();
    this.fetchProducts();
  }

  componentWillUnmount() {
    this.stopAutoSlide();
  }

  // Start auto-slide
  startAutoSlide = () => {
    this.stopAutoSlide(); // Clear any existing intervals
    this.autoSlideInterval = setInterval(() => {
      this.setState((prevState) => ({
        currentIndex: (prevState.currentIndex + 1) % prevState.artworks.length,
      }));
    }, 60000); // 1 minute
  };

  // Stop auto-slide
  stopAutoSlide = () => {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  };

  // Handle manual navigation
  handleNext = () => {
    this.setState(
      (prevState) => ({
        currentIndex: (prevState.currentIndex + 1) % prevState.artworks.length,
      }),
      this.startAutoSlide // Reset the timer
    );
  };

  handleIndicatorClick = (index) => {
    this.setState({ currentIndex: index }, this.startAutoSlide); // Reset the timer
  };

  fetchProducts = async () => {
    try {
      const response = await getAllProducts();
      this.setState({
        products: response["content"].slice(0, 8),
        loading: false
      });
      
    } catch (err) {
      this.setState({
        error: 'Failed to fetch products',
        loading: false
      });
      
    }
  }

  // renderProducts = () => {
    
  //   if (loading) return <div>Loading products...</div>;
  //   if (error) return <div>{error}</div>;
    
  //   return (
  //     <div className="products-grid">
  //       {products.map((product) => (
  //         <div key={product.id} className="product-card">
  //           <img src={product.image} alt={product.name} />
  //           <h3>{product.name}</h3>
  //           <p>${product.price}</p>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  render() {
    const { currentIndex, artworks } = this.state;
    
    const { products, loading, error } = this.state;
    const genericSection01Header = {
      title: 'Are you a unique creative hand-made product creator?'
    }   

    const images = [
      { id: 1, src: img1, alt: 'Image 1' },
      { id: 2, src: img1, alt: 'Image 2' },
      { id: 3, src: img1, alt: 'Image 3' },
      { id: 4, src: img1, alt: 'Image 4' },
      { id: 5, src: img1, alt: 'Image 5' },
      { id: 6, src: img1, alt: 'Image 6' },
      { id: 7, src: img1, alt: 'Image 7' },
      { id: 8, src: img1, alt: 'Image 8' },
      { id: 9, src: img1, alt: 'Image 9' },
      { id: 10, src: img1, alt: 'Image 10' },
    ];
    
    const slides = [
{
image: require('../assets/images/vendor.jpg'),
title: 'Vendors',
description: 'You can sell your handmade products on Arena',
// btnText : "Sign Up Now",
btnText : <div style={{ display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
  {/* <a href="https://bit.ly/CAMarena" style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
  Sign Up Now
  </Button>
</a> */}
<a href="https://play.google.com/store/apps/details?id=com.thearenang.arena&pcampaignid=web_share" style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
Download Android Now
  </Button>
</a>
</div>
,
// btnLink : "https://bit.ly/CAMarena",
},
{
  image: require('../assets/images/shoppers.jpeg'),
  title: 'Shoppers',
  description: 'Discover amazing handmade products and vintage items for your personal collections',
  // btnText : "Join the waitlist",
  btnText : <div style={{ display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
  {/* <a href="https://bit.ly/CAMarena" style={{margin:"10px 10px"}}> */}
  {/* <a href="#" style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
  ios is Coming
  </Button>
</a> */}
<a href="https://play.google.com/store/apps/details?id=com.thearenang.arena&pcampaignid=web_share " style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
Download Android Now
  </Button>
</a>
</div>,
  // btnLink : "#wailist",
},
// {
// image: 'https://via.placeholder.com/1200x600',
// title: 'Slide 3',
// description: 'Description for Slide 3',
// },
];
    return (
      <React.Fragment>
        {/* <HeroSplit hasBgColor invertColor /> */}

{/* <HeroSlider slides={slides} />; */}
<header className='banner'>
  <section className='banner-inner'>
    <div className='txt'>
      <p className='banner-inner-tag'>New Arrival</p>
      <h3 className='title'>Exclusive Collections</h3>
      <p className='desc'>Discover amazing handmade products and vintage items for your personal collections</p>
      <Button className='banner-inner-btn button-primary'>Shop Now</Button>
    </div>
  </section>
</header>

        {/* <FeaturesTabs topDivider bottomOuterDivider />
        <FeaturesTiles topDivider bottomOuterDivider /> */}
        <News className="illustration-section-01" />
        <Products className="illustration-section-01" products={products} />

          <section className='exhibition'>
            <div className='inner-left'>
              <h3 style={{color:"#091D1B"}}>50+ Beautiful Art collectibles</h3>
              <p>Our creative art creators already made a lot of beautiful art and design the tells unique stories</p>
              <Button className="button-primary">Explore More</Button>
            </div>
            <div className='inner-right'>
              <section className='active'>
                <div className="art-slide">
                  
                <img className='active-img' src={artworks[currentIndex].img}></img>
                  </div>
                <div className='activer-inner'>
                  <p className='tag'>{artworks[currentIndex].id+1} - {artworks[currentIndex].category}</p>
                  <h4 className='title'>{artworks[currentIndex].title}</h4>
                </div>
              </section>
              <section className='exhibitions'>
              <div
            className="artworks"
            style={{ transform: `translateX(-${currentIndex * 210}px)` }}
          >
            {artworks.map((art, index) => (
              <div key={index} className=" active-img art-slide">
                <img src={art.img} alt={art.title} />
                {/* <div className="art-info">
                  <p className="tag">{art.category}</p> */}
                  {/* <p className="title">{art.title}</p> */}
                {/* </div> */}
              </div>
            ))}
          </div>
          <div className="indicators">
          {artworks.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentIndex ? "active" : ""}`}
              onClick={() => this.handleIndicatorClick(index)}
            />
          ))}
        </div>
                <button onClick={this.handleNext} className="next-arrow">
                  <img src={next} />
          </button>
              </section>
            </div>
          </section>
        <section style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>

          <h5 className='subtitle'>Share your unique collection with</h5>
          <h1 className='title' style={{ color:"#091D1B"}}>#TheArenaNG</h1>

        <div className="gallery">
      {images.map((image) => (
        <div className="gallery-item" key={image.id}>
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
        </section>

    <hr width="100%" />
        {/* <Roadmap topOuterDivider /> */}
        {/* <Pricing hasBgColor pricingSlider /> */}
        {/* <ShopperForm/> */}
        {/* <GenericSection topDivider> */}
          {/* <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" tag="h3" />
            <div className="center-content">
              <Button
                color="secondary"
                aria-controls="demo-modal"
                style={{maxWidth:"100%"}}
                onClick={this.openModal}>Sign Up to Showcase Your Products with Us</Button>
            </div> */}
            <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Join the community creative creators to showcase and sell your products</h3>
                <p className="text-sm">
                Do you create hand-made local products across jewelry, clothing, pottery, woodworks, leatherworks, waste works and so on? Do you have vintage collections to sell?
                </p>
              </div>
              {/* <form style={modalFormStyle}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label="This is a label"
                    placeholder="Your best email"
                    labelHidden />
                </div> */}
                <Button onClick={() => window.open("https://bit.ly/CAMarena")} color="primary" wide>Join Now</Button>
              {/* </form> */}
              <div className="center-content mt-24">
                <a
                  className="text-xs"
                  aria-label="close"
                  href="#0"
                  onClick={this.closeModal}
                >No thanks!</a>
              </div>
            </Modal>
          {/* </div > */}
        {/* </GenericSection>  */}
        {/* <Cta hasBgColor invertColor split /> */}
      </React.Fragment>
    );
  }
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}
export default Home;