// import React, { Component } from 'react';
// import Modal from 'react-modal';
// import Spinner from '../Spinner/Spinner';
// import ErrorToast from '../ErrorToast/ErrorToast';

// class CreateProductModal extends Component {
//   componentDidMount = () => {
//     const { getCategories } = this.props;
//     getCategories();
//   };

//   handleProductCreation = e => {
//     e.preventDefault();

//     const { modalErrors, createProduct, clearModalErrors, closeModal } = this.props;

//     if (modalErrors.length) clearModalErrors();

//     const productImage = this.productImage.files[0];
//     const productName = this.productName.value;
//     const productPrice = this.productPrice.value.replace(/,/g, '');
//     const productQty = this.productQty.value;
//     const productCategory = this.productCategory.value;

//     const formData = new FormData();
//     formData.append('imageUrl', productImage);
//     formData.append('name', productName);
//     formData.append('price', productPrice);
//     formData.append('qty', productQty);
//     formData.append('categoryid', productCategory);

//     createProduct(formData, () => closeModal());
//   };

//   render() {
//     const {
//       modalOpenState,
//       closeModal,
//       modalErrors,
//       modalLoading,
//       categories: { allCategories }
//     } = this.props;

//     return (
//       <Modal
//         isOpen={modalOpenState}
//         onRequestClose={closeModal}
//         shouldCloseOnEsc={true} // Allow closing on ESC key press
//         shouldCloseOnOverlayClick={true}
//         ariaHideApp={false}
//         contentLabel="New Product"
//         overlayClassName="modal-overlay"
//         className={`modal ${modalOpenState ? 'is-active' : ''}`}
//         // className={`modal`}
//       >
//         <div className="form-body">
//           <h3>Create Product</h3>
//           <span role="link" tabIndex={0} onKeyDown={() => {}} className="form__closeBtn" onClick={closeModal}>
//             &times;
//           </span>

//           <form id="create-new-product" encType="multipart/form-data" onSubmit={this.handleProductCreation}>
//             {modalErrors.length ? <ErrorToast errors={modalErrors} /> : null}
//             <div className="input-group">
//               <label htmlFor="product-image">
//                 Product Image
//                 <input
//                   ref={productImage => (this.productImage = productImage)}
//                   type="file"
//                   id="product-image"
//                   accept=".jpg"
//                 />
//               </label>
//             </div>
//             <div className="input-group">
//               <label htmlFor="product-name">
//                 Product Name
//                 <input
//                   ref={productName => (this.productName = productName)}
//                   id="product-name"
//                   type="text"
//                   placeholder="Product Name"
//                   required
//                 />
//               </label>
//             </div>
//             <div className="input-group">
//               <label htmlFor="product-price">
//                 Product Price
//                 <input
//                   ref={productPrice => (this.productPrice = productPrice)}
//                   id="product-price"
//                   type="text"
//                   placeholder="Product Price"
//                   required
//                 />
//               </label>
//             </div>
//             <div className="input-group">
//               <label htmlFor="product-qty">
//                 Product Qty
//                 <input
//                   ref={productQty => (this.productQty = productQty)}
//                   id="product-qty"
//                   type="text"
//                   placeholder="Product Quantity"
//                   required
//                 />
//               </label>
//             </div>
//             <div className="input-group">
//               <label htmlFor="product-cat">
//                 Product Category:
//                 <select ref={productCategory => (this.productCategory = productCategory)} id="product-cat" required>
//                   <option defaultValue="All">Select Category</option>
//                   {allCategories.map(category => {
//                     return (
//                       <option key={category.category_id} value={category.category_id}>
//                         {category.category_name}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </label>
//             </div>
//             <div className="input-group">
//               <button type="submit" disabled={modalLoading ? true : null} className="btn btn--gradient full-width">
//                 {modalLoading ? <Spinner /> : 'Create Product'}
//               </button>
//             </div>
//           </form>
//         </div>
//       </Modal>
//     );
//   }
// }

// export default CreateProductModal;


import React, { Component } from 'react';
import Modal from 'react-modal';
import Spinner from '../Spinner/Spinner';
import ErrorToast from '../ErrorToast/ErrorToast';

class CreateProductModal extends Component {
  componentDidMount = () => {
    const { getCategories } = this.props;
    getCategories();
  };

  handleProductCreation = e => {
    e.preventDefault();

    const { modalErrors, createProduct, clearModalErrors, closeModal } = this.props;

    if (modalErrors.length) clearModalErrors();

    const formData = new FormData();
    
    const user = JSON.parse(localStorage.getItem('userInfo')) || '';
    // Collect all form data
    formData.append('userId', user.id);
    formData.append('name', this.productName.value);
    formData.append('price', this.productPrice.value.replace(/,/g, ''));
    formData.append('description', this.productDescription.value);
    formData.append('story', this.productStory.value);
    formData.append('size', this.productSize.value);
    formData.append('color', this.productColor.value);
    formData.append('weight', this.productWeight.value);
    formData.append('height', this.productHeight.value);
    formData.append('priceAfetDiscount', this.productPriceAfterDiscount.value ?? this.productPrice.value);
    formData.append('dicountpercent', this.productDiscountPercent.value);
    formData.append('width', this.productWidth.value);
    formData.append('quantity', this.productQuantity.value);
    formData.append('categoryId', this.productCategory.value);

    // Collect images
    formData.append('image', this.productImageUrl.files[0]);
    formData.append('image2', this.productImage2.files[0]);
    formData.append('image3', this.productImage3.files[0]);
    formData.append('image4', this.productImage4.files[0]);
    formData.append('image5', this.productImage5.files[0]);

    createProduct(formData, () => closeModal());
  };

  render() {
    const {
      modalOpenState,
      closeModal,
      modalErrors,
      modalLoading,
      categories: { allCategories }
    } = this.props;

    return (
      <Modal
        isOpen={modalOpenState}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        contentLabel="New Product"
        overlayClassName="modal-overlay"
        className={`modal ${modalOpenState ? 'is-active' : ''}`}
      >
        <div className="form-body two-sided-form" style={{ maxWidth:"90%", width:"auto"}}>
          <h3>Create Product</h3>
          <span role="button" tabIndex={0} onClick={closeModal} className="form__closeBtn">
            &times;
          </span>

          <form id="create-new-product" encType="multipart/form-data" onSubmit={this.handleProductCreation}>
            {modalErrors.length ? <ErrorToast errors={modalErrors} /> : null}

            <div className="form-sides">
              {/* Left Side */}
              <div className="form-side">
                <div className="input-group">
                  <label htmlFor="product-name">Product Name <sup>*</sup></label>
                  <input
                    ref={el => (this.productName = el)}
                    id="product-name"
                    type="text"
                    placeholder="Enter product name"
                    required
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="product-price">Product Price</label>
                  <input
                    ref={el => (this.productPrice = el)}
                    id="product-price"
                    type="number"
                    step="0.01"
                    placeholder="Enter price"
                    required
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="product-description">Description</label>
                  <textarea
                    ref={el => (this.productDescription = el)}
                    id="product-description"
                    placeholder="Enter description"
                    rows="4"
                    required
                  ></textarea>
                </div>

                <div className="input-group">
                  <label htmlFor="product-story">Story Behind it</label>
                  <textarea
                    ref={el => (this.productStory = el)}
                    id="product-story"
                    placeholder="Enter story"
                    rows="4"
                  ></textarea>
                </div>

              </div>

              {/* Right Side */}
              <div className="form-side">
                <div className="form-sides">
                <div className="form-side input-group" style={{minWidth: "50px",
    maxWidth: "50%"}}>
                  <label htmlFor="product-size">Sizes</label>
                  <input
                    ref={el => (this.productSize = el)}
                    id="product-size"
                    type="text"
                    placeholder="Enter size"
                  />
                </div>

                <div className="form-side input-group" style={{minWidth: "50px",
    maxWidth: "50%"}}>
                  <label htmlFor="product-color">Colors</label>
                  <input
                    ref={el => (this.productColor = el)}
                    id="product-color"
                    type="text"
                    placeholder="Enter color"
                  />
                </div>
                </div>

                <div className="input-group">
                  <label htmlFor="product-category">Category</label>
                  <select
                    ref={el => (this.productCategory = el)}
                    id="product-category"
                    required
                  >
                    <option value="">Select a category</option>
                    {allCategories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-sides">

                <div className="form-side input-group" style={{minWidth: "50px",
    maxWidth: "50%"}}>
                  <label htmlFor="product-weight">Weight (kg)</label>
                  <input
                    ref={el => (this.productWeight = el)}
                    id="product-weight"
                    type="number"
                    step="0.01"
                  />
                </div>

                <div className=" form-side input-group" style={{minWidth: "50px",
    maxWidth: "50%"}}>
                  <label htmlFor="product-height">Height (cm)</label>
                  <input
                    ref={el => (this.productHeight = el)}
                    id="product-height"
                    type="number"
                    step="0.01"
                  />
                </div>

                </div>

                <div className="form-sides">
                <div className="form-side input-group" style={{minWidth: "50px",
    maxWidth: "50%"}}>
                  <label htmlFor="product-width">Width (cm)</label>
                  <input
                    ref={el => (this.productWidth = el)}
                    id="product-width"
                    type="number"
                    step="0.01"
                  />
                </div>

                <div className="form-side input-group" style={{minWidth: "50px",
    maxWidth: "50%"}}>
                  <label htmlFor="product-quantity">Quantity</label>
                  <input
                    ref={el => (this.productQuantity = el)}
                    id="product-quantity"
                    type="number"
                    required
                  />
                </div>

                </div>

                <div className="input-group">
                  <label htmlFor="product-discount">Discount Percent</label>
                  <input
                    ref={el => (this.productDiscountPercent = el)}
                    id="product-discount"
                    type="number"
                    step="0.01"
                    onChange={
                  (e) => {
                    const price = parseFloat(this.productPrice.value) || 0;
                    const discount = parseFloat(e.target.value) || 0;
                    const priceAfterDiscount = price - (price * discount / 100);
                    this.productPriceAfterDiscount.value = priceAfterDiscount.toFixed(2);
                  }}
                  />
                  <input
                    ref={el => (this.productPriceAfterDiscount = el)}
                    id="product-price-after-discount"
                    type="number"
                    step="0.01"
                    style={{ display: 'none' }}
                  />
                  {/* <small>Price After Discount : {this.productPriceAfterDiscount}</small> */}
                  
                </div>
              </div>

              {/* Right Side */}
              <div className="form-side">
                
                {/* Image Uploads */}
                <div className="input-group">
                  <label>Primary Image</label>
                  <input ref={el => (this.productImageUrl = el)} type="file" required />
                </div>
                <div className="input-group">
                  <label>Additional Image 2</label>
                  <input ref={el => (this.productImage2 = el)} type="file" />
                </div>
                <div className="input-group">
                  <label>Additional Image 3</label>
                  <input ref={el => (this.productImage3 = el)} type="file" />
                </div>
                <div className="input-group">
                  <label>Additional Image 4</label>
                  <input ref={el => (this.productImage4 = el)} type="file" />
                </div>
                <div className="input-group">
                  <label>Additional Image 5</label>
                  <input ref={el => (this.productImage5 = el)} type="file" />
                </div>
              </div>
            </div>

            <div className="input-group">
              <button type="submit" disabled={modalLoading} className="btn btn--gradient full-width">
                {modalLoading ? <Spinner /> : 'Create Product'}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default CreateProductModal;
