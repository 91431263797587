import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import appIcon from '../../assets/images/arena-logo-white.svg';
import '../../assets/scss/components/_appBanner.scss';

const AppBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      localStorage.setItem('hasSeenAppBanner', 'false');
    // const hasSeenBanner = localStorage.getItem('hasSeenAppBanner');
    const hasSeenBanner = false;
    if (!hasSeenBanner) {
      setTimeout(() => setIsVisible(true), 2000);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('hasSeenAppBanner', 'true');
  };

  if (!isVisible) return null;

  return (
    <div className="app-banner">
      <div className="app-banner__content">
        <img 
          src={appIcon} 
          alt="Arena App" 
          className="app-banner__icon"
        />
        <div className="app-banner__text">
          <h3>Get the Arena App</h3>
          <p>Shop faster and easier with our mobile app</p>
        </div>
      </div>
      <a 
        href="https://play.google.com/store/apps/details?id=com.thearenang.arena&pcampaignid=web_share" 
        target="_blank" 
        rel="noopener noreferrer"
        className="app-banner__cta"
      >
        Download Now
      </a>
      <button 
        onClick={handleClose}
        className="app-banner__close"
        aria-label="Close banner"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default AppBanner;