import React from 'react';
import Util from '../../utils';

const TableRow = ({ latestsales = [] }) => {
  if (!latestsales.length) {
    return (
      <tr>
        <td colSpan="6">No sale has been recorded yet.</td>
      </tr>
    );
  }
  return latestsales.map((sale, index) => {
    return (
      <tr key={index}>
        <td colSpan="1">{Util.formatDate(sale.timestamp)}</td>
        <td>{sale.products && sale.products.name ? sale.products.name : ''}</td>
        <td>{sale.quantity}</td>
        <td>{Util.formatCurrency(sale.amount)}</td>
        <td>{Util.formatCurrency(sale.quantity * sale.amount)}</td>
      </tr>
    );
  });
};

export default TableRow;
