import React from 'react';
import Helmet from 'react-helmet';
import Main from '../../components/Main/Main';
import SetupForm from '../../components/RegisterForm/SetupForm';

const SetupPage = () => (
  <>
    <Helmet>
      <title>Get Started - Arena Storefront</title>
    </Helmet>
    <Main>
      <SetupForm />
    </Main>
  </>
);

export default SetupPage;
