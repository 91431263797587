import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as vendorActions from '../../actions/profileActions/profileActions';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import Util from '../../utils';
import './Support.scss';
import CreateProductModal from '../Modals/CreateProductModal';
import UpdateProductModal from '../Modals/UpdateProductModal';
import DeleteProductModal from '../Modals/DeleteProductModal';
import ToastContainer from '../Toasts/ToastContainer';

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      issue: '',
      message: '',
      submitted: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });

    // Clear message after 5s
    setTimeout(() => this.setState({ submitted: false }), 5000);
  };

  render() {
    const { name, email, issue, message, submitted } = this.state;

    return (
      <div className="support-page">
        <h1>Support Center</h1>
        <p>Choose your preferred mode of contact to get help:</p>

        {/* Contact Methods */}
        <div className="contact-methods">
          <div className="method">
            <h3>📞 Phone</h3>
            <p><strong> +234 904 444 4157</strong></p>
          </div>

          <div className="method">
            <h3>📧 Email</h3>
            <p>Drop us an email: &nbsp; <a href="mailto:support@example.com">support@thearenang.com</a></p>
          </div>

          <div className="method">
            <h3>💬 WhatsApp</h3>
            <p>
              Chat with us on WhatsApp: &nbsp; 
              <a
                href="https://wa.me/2349044444157"
                target="_blank"
                rel="noopener noreferrer"
              >
                Start Chat
              </a>
            </p>
          </div>
        </div>

        {/* Submit Ticket Form */}
        <div className="submit-ticket">
          <h2>🎫 Submit a Support Ticket</h2>
          {submitted ? (
            <div className="success-message">
              🎉 Your ticket has been submitted! We'll get back to you soon.
            </div>
          ) : (
            <form onSubmit={this.handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  required
                />
              </label>

              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
              </label>

              <label>
                Issue:
                <select
                  name="issue"
                  value={issue}
                  onChange={this.handleChange}
                  required
                >
                  <option value="">-- Select an issue --</option>
                  <option value="technical">Technical Support</option>
                  <option value="billing">Billing Issue</option>
                  <option value="general">General Query</option>
                </select>
              </label>

              <label>
                Message:
                <textarea
                  name="message"
                  value={message}
                  onChange={this.handleChange}
                  rows="5"
                  required
                ></textarea>
              </label>

              <button type="submit">Submit Ticket</button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default Support;
