import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faDownload, faGifts, faHeart, faPerson, faSearch, faUserAlt, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faUsersLine } from '@fortawesome/free-solid-svg-icons/faUsersLine';
import AppBanner from '../AppBanner/AppBanner';

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

class Header extends React.Component {

  fetchCartItems = () => {
    const cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      return cart.length;
    }
    return 0;
  }

  fetchApprovedOrders = () => {
    const orders = JSON.parse(localStorage.getItem('orders'));
    if (orders) {
      return orders.filter(order => order.status === 'approved').length;
    }
    return 0;
  }
  state = {
    isActive: false,
    cartItems: this.fetchCartItems(),
    approvedOrders: this.fetchApprovedOrders()
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  }

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  }

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  }

  clickOutside = (e) => {
    if (!this.nav.current) return
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) return;
    this.closeMenu();
  }


  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      cartItems,
      approvedOrders,
      ...props
    } = this.props;

    const classes = classNames(
      'site-header',
      bottomOuterDivider && 'has-bottom-divider',
      className
    );
    // const navigate = useNa

    return (
      <>
      <header
        {...props}
        className={classes}
      >
        <div className="container">
        <div className={
          classNames(
          'site-header-inner',
          bottomDivider && 'has-bottom-divider'
          )}>
          <Logo />
          {!hideNav &&
          <React.Fragment>
            <button
            style={{    color: "white"}}
            ref={this.hamburger}
            className="header-nav-toggle"
            onClick={this.state.isActive ? this.closeMenu : this.openMenu}
            >
            <span className="screen-reader">Menu</span>
            <span className="hamburger">
              <span className="hamburger-inner"></span>
            </span>
            </button>
            <nav
            ref={this.nav}
            className={
              classNames(
              'header-nav',
              this.state.isActive && 'is-active'
              )}>
              
            <div className="header-nav-inner">
              <ul className={
              classNames(
                'list-reset text-xs',
                navPosition && `header-nav-${navPosition}`
              )}>
              <li>
                <Link to="/" onClick={this.closeMenu}>Home</Link>
              </li>
              <li>
                <Link to="/shop" onClick={this.closeMenu}>Shop</Link>
              </li>
              <li>
                <Link to="/about" onClick={this.closeMenu}>About</Link>
              </li>
              <li>
                <Link to="/contact" onClick={this.closeMenu}>Contact</Link>
              </li>
              </ul>
              <ul className="list-reset header-nav-right">
              <li style={{display:"flex", justifyContent:"center"}}>
                {localStorage.getItem('token') ? (
                <>
                  <div className="dropdown" style={{ position: 'relative' }}>
                  <Link to="#" onClick={(e) => {
                    e.preventDefault();
                    this.setState(prev => ({ showDropdown: !prev.showDropdown }));
                  }} style={{padding:0, margin:"5px 10px"}} title="Profile"> 
                    <FontAwesomeIcon icon={faUserAlt}></FontAwesomeIcon>
                  </Link>
                  {this.state.showDropdown && (
                    <div style={{
                    position: 'absolute',
                    top: '100%',
                    right: 0,
                    backgroundColor: 'white',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                    zIndex: 1000,
                    minWidth: '150px'
                    }}>
                    <Link to="/login" onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('user');
                      localStorage.clear();
                      // window.location.reload();
                    }} style={{display: 'block', padding: '10px', color: '#333', textDecoration: 'none'}}>Logout</Link>
                    </div>
                  )}
                  </div>

                  {/* <Link to="/orders" style={{padding:0, margin:"5px 10px", position: "relative"}} title="Orders"> 
                  <FontAwesomeIcon icon={faGifts}></FontAwesomeIcon>
                  {this.state.approvedOrders > 0 && (
                    <span style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    background: "#ff4f4f",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "10px",
                    color: "white"
                    }}>
                    {this.state.approvedOrders}
                    </span>
                  )}
                  </Link> */}

                  <Link to="/cart" style={{padding:0, margin:"5px 10px", position: "relative"}} title="Cart"> 
                  <FontAwesomeIcon icon={faCartShopping}></FontAwesomeIcon>
                  {this.state.cartItems > 0 && (
                    <span style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    background: "#ff4f4f",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "10px",
                    color: "white"
                    }}>
                    {this.state.cartItems}
                    </span>
                  )}
                  </Link>
                </>
                ) : (
                <>
                  <Link to="/login" className="button button-primary button-wide-mobile button-sm" style={{margin: "0 5px"}}>
                  Login
                  </Link>
                  <Link to="/register" className="button button-wide-mobile button-sm" style={{margin: "0 5px"}}>
                  Register
                  </Link>
                </>
                )}
              </li>
              </ul>
            </div>
            </nav>
          </React.Fragment>}
        </div>
        </div>
      </header>
      <AppBanner />
      </>
    )
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;