// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-main {
  flex: 1 1;
}
@media (min-width: 1023px) {
  #site-main {
    display: flex;
    align-items: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Main/Main.scss","webpack://./src/assets/styles/abstract/_mixin.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;AADF;ACDE;EDCF;IAII,aAAA;IACA,uBAAA;EAAF;AACF","sourcesContent":["@import '../../assets/styles/abstract/index';\n\n#site-main {\n  flex: 1;\n\n  @include desktop {\n    display: flex;\n    align-items: flex-start;\n  }\n}\n","@mixin desktop {\n  @media (min-width: #{$desktop-width}) {\n    @content;\n  }\n}\n\n@mixin tab {\n  @media (min-width: #{$tab-width}) {\n    @content;\n  }\n}\n\n@mixin mob-land {\n  @media (min-width: #{$mobile-landscape}) {\n    @content;\n  }\n}\n\n@mixin retina {\n  @media (min-width: #{$retina-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
