import React from "react";
import PageHeader from "../components/sections/PageHeader";

const Payment_options = () => {

    return (
        <React.Fragment>
            <PageHeader page="Payment Options"></PageHeader>
        </React.Fragment>
    );
}

export default Payment_options;