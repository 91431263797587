import axios from 'axios';

class FlutterwaveApi {
    constructor() {
        this.token = "FLWSECK_TEST-1f92b1e78e685f316fb276f44e0229bf-X";
        this.baseUrl = 'https://api.flutterwave.com/v3/';
        this.axiosInstance = axios.create({
            baseUrl: this.baseUrl,
            timeout: 60000,
        });
    }

    setAuthorizationHeader(authenticate = true) {
        if (authenticate) {
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        } else {
            delete this.axiosInstance.defaults.headers.common['Authorization'];
        }
    }

    async isDeviceConnected() {
        try {
            await axios.get('https://example.com');
            return true;
        } catch (error) {
            return false;
        }
    }

    handleApiError = async (error) => {
        let message = "";
        
        if (error.response?.data) {
            message = error.response.data.message;
        } else {
            switch (error.code) {
                case 'ERR_NETWORK':
                    message = "Timeout Error: The connection or operation timed out. Please check your internet connection and try again later.";
                    break;
                case 'ECONNABORTED':
                    message = "Connection Timeout: Unable to establish a connection.";
                    break;
                default:
                    const deviceConnected = await this.isDeviceConnected();
                    message = deviceConnected 
                        ? "Unknown Error: An error has occurred. Please try again later or contact support for assistance." 
                        : "No Network Connection";
            }
        }
        
        // You can integrate this with your preferred toast/notification system
        
        return message;
    }

    async payVendor(data) {
        try {
            this.setAuthorizationHeader(true);
            const response = await this.axiosInstance.post('transfers', data);
            
            return response;
        } catch (error) {
            const errorMessage = await this.handleApiError(error);
            throw new Error(errorMessage);
        }
    }

    async getBanks() {
        try {
            this.setAuthorizationHeader(true);
            const response = await this.axiosInstance.get('banks/NG');
            
            return response;
        } catch (error) {
            const errorMessage = await this.handleApiError(error);
            throw new Error(errorMessage);
        }
    }

    async get(url, queryParameters = null, authenticate = true) {
        try {
            this.setAuthorizationHeader(authenticate);
            const response = await this.axiosInstance.get(url, { params: queryParameters });
            return response;
        } catch (error) {
            const errorMessage = await this.handleApiError(error);
            throw new Error(errorMessage);
        }
    }

    async post(url, data = null, authenticate = true) {
        try {
            this.setAuthorizationHeader(authenticate);
            const response = await this.axiosInstance.post(url, data);
            return response;
        } catch (error) {
            const errorMessage = await this.handleApiError(error);
            throw new Error(errorMessage);
        }
    }
}

export default FlutterwaveApi;
