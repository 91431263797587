import React from "react";
import About from "../components/sections/about";
import PageHeader from "../components/sections/PageHeader";

const AboutPage = () => {

    return (
        <React.Fragment>
            <PageHeader page="About"></PageHeader>
<About invertMobile imageFill bottomOuterDivider/>
        </React.Fragment>

    );
}

export default AboutPage;