import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './StoreSetup.scss';

const StoreSetupForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    storeName: '',
    description: '',
    category: '',
    phone: '',
    address: '',
    isRegistered: false,
    registeredNumber: '',
    NIN: '',
    proofOfAddress: null,
    meansOfIdentification: null,
    CACDocument: null,
    otherDocuments: null,
    bank: '',
    accountNumber: '',
    openingHours: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit logic here
  };

  return (
    <div className="store-setup">
      <h2>Store Setup - Step {step}</h2>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div>
            <h3>Store Info</h3>
            <label>
              Store Name
              <input
                type="text"
                name="storeName"
                value={formData.storeName}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Description
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Category
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Phone
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Address
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Is Registered?
              <input
                type="checkbox"
                name="isRegistered"
                checked={formData.isRegistered}
                onChange={handleInputChange}
              />
            </label>
            {formData.isRegistered && (
              <label>
                Registered Number
                <input
                  type="text"
                  name="registeredNumber"
                  value={formData.registeredNumber}
                  onChange={handleInputChange}
                />
              </label>
            )}
            <label>
              NIN
              <input
                type="text"
                name="NIN"
                value={formData.NIN}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
        )}

        {step === 2 && (
          <div>
            <h3>Documents</h3>
            <label>
              Proof of Address
              <input
                type="file"
                name="proofOfAddress"
                onChange={handleFileChange}
                required
              />
            </label>
            <label>
              Means of Identification
              <input
                type="file"
                name="meansOfIdentification"
                onChange={handleFileChange}
                required
              />
            </label>
            {formData.isRegistered && (
              <label>
                CAC Document
                <input
                  type="file"
                  name="CACDocument"
                  onChange={handleFileChange}
                />
              </label>
            )}
            <label>
              Other Documents
              <input
                type="file"
                name="otherDocuments"
                onChange={handleFileChange}
                multiple
              />
            </label>
          </div>
        )}

        {step === 3 && (
          <div>
            <h3>Bank Details</h3>
            <label>
              Bank
              <select
                name="bank"
                value={formData.bank}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a bank</option>
                <option value="Bank 1">Bank 1</option>
                <option value="Bank 2">Bank 2</option>
                <option value="Bank 3">Bank 3</option>
              </select>
            </label>
            <label>
              Account Number
              <input
                type="text"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
        )}

        {step === 4 && (
          <div>
            <h3>Opening Hours</h3>
            <label>
              Enter Your Opening Hours
              <input
                type="text"
                name="openingHours"
                value={formData.openingHours}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
        )}

        <div className="form-navigation">
          {step > 1 && <button type="button" onClick={prevStep}>Previous</button>}
          {step < 4 && <button type="button" onClick={nextStep}>Next</button>}
          {step === 4 && <button type="submit">Submit</button>}
        </div>
      </form>
    </div>
  );
};

StoreSetupForm.propTypes = {};

export default StoreSetupForm;
