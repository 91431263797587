import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import salesRecordIcon from '../../assets/img/sales-rec-icon.png';
import * as productActions from '../../actions/productActions/productActions';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import Util from '../../utils';
import CreateProductModal from '../Modals/CreateProductModal';
import * as categoryActions from '../../actions/categoryActions/categoryActions';
import ToastContainer from '../Toasts/ToastContainer';
import UpdateProductModal from '../Modals/UpdateProductModal';
import DeleteProductModal from '../Modals/DeleteProductModal';
import './Payments.scss';


class PaymentsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earningsOverview: {
        totalEarnings: 5000,
        monthlyEarnings: 1200,
        weeklyEarnings: 300,
        withdrawableBalance: 2000,
        pendingEarnings: 150,
      },
      paymentBreakdown: {
        productsSold: 4000,
        servicesOffered: 800,
        platformFeesDeducted: 200,
      },
      transactions: [
        {
          transactionId: 'TXN001',
          itemsPurchased: 'Handmade Necklace',
          amountEarned: 50,
          status: 'Completed',
          date: '2024-12-17',
        },
        {
          transactionId: 'TXN002',
          itemsPurchased: 'Vintage Table',
          amountEarned: 120,
          status: 'Pending',
          date: '2024-12-15',
        },
        {
          transactionId: 'TXN003',
          itemsPurchased: 'Crochet Sweater',
          amountEarned: 80,
          status: 'Completed',
          date: '2024-12-10',
        },
      ],
      payoutHistory: [
        {
          payoutId: 'Payout001',
          amount: 500,
          date: '2024-12-01',
          status: 'Processed',
        },
        {
          payoutId: 'Payout002',
          amount: 800,
          date: '2024-11-15',
          status: 'Processed',
        },
      ],
    };
  }

  handleRequestPayout = (payoutId) => {
    alert(`Payout request for ${payoutId} initiated.`);
  };
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     productData: {},
  //     setCreateModalOpen: false,
  //     updateModalIsOpen: false,
  //     deleteModalIsOpen: false
  //   };
  // }

  componentDidMount() {
    const { getProducts } = this.props;
    getProducts();
  }

  openUpdateModal = productData => {
    this.setState({ updateModalIsOpen: true, productData });
  };

  openDeleteModal = productData => {
    this.setState({ deleteModalIsOpen: true, productData });
  };

  openModal = () => {
    this.setState({ setCreateModalOpen: true });
  };

  closeModal = () => {
    const {
      clearModalErrors,
      products: { modalErrors }
    } = this.props;
    this.setState({ setCreateModalOpen: false, updateModalIsOpen: false, deleteModalIsOpen: false });
    if (modalErrors.length) clearModalErrors();
  };

  render() {
    const { earningsOverview, paymentBreakdown, transactions, payoutHistory } = this.state;

    const {
      products,
      categories,
      products: { productsList, isLoading, modalErrors, modalLoading, message: actionMessage },
      createProduct,
      deleteProduct,
      updateProduct,
      clearModalErrors,
      getCategories
    } = this.props;


    const { setCreateModalOpen, productData, updateModalIsOpen, deleteModalIsOpen } = this.state;

    if (isLoading) {
      return <Loading title="Fectching Products" />;
    }
    return (
      <div className="payments-page">
        {/* <h1>Payments Overview</h1> */}

        {/* Earnings Overview */}
        <section className="summary">
          <div className="summary__card">
            <div className="details">
              <p className="header">Total Earnings</p>
              <h2 className="value" id="total-sales-orders">
                {earningsOverview.totalEarnings}
              </h2>
            </div>
            <div className="icon yellow">
              <img src={salesRecordIcon} alt="card-icon" />
            </div>
          </div>
          <div className="summary__card">
            <div className="details">
              <p className="header">Monthly Earnings</p>
              <h2 className="value" id="total-prod-sold">
                {earningsOverview.monthlyEarnings}
              </h2>
            </div>
            <div className="icon red">
              <img src={salesRecordIcon} alt="card-icon" />
            </div>
          </div>
          <div className="summary__card">
            <div className="details">
              <p className="header">Withdrawable Balance:</p>
              <h2 className="value" id="total-prod-worth">
                {Util.formatCurrency(earningsOverview.withdrawableBalance)}
              </h2>
            </div>
            <div className="icon green">
              <img src={salesRecordIcon} alt="card-icon" />
            </div>
          </div>
          <div className="summary__card">
            <div className="details">
              <p className="header">Pending Earnings:</p>
              <h2 className="value" id="total-prod-worth">
                {Util.formatCurrency(earningsOverview.pendingEarnings)}
              </h2>
            </div>
            <div className="icon green">
              <img src={salesRecordIcon} alt="card-icon" />
            </div>
          </div>
        </section>
        {/* <section className="earnings-overview">
          <h2>Earnings Overview</h2>
          <ul>
            <li>Total Earnings (Lifetime): ${earningsOverview.totalEarnings}</li>
            <li>Monthly Earnings: ${earningsOverview.monthlyEarnings}</li>
            <li>Withdrawable Balance: ${earningsOverview.withdrawableBalance}</li>
            <li>Pending Earnings: ${earningsOverview.pendingEarnings}</li>
          </ul>
        </section> */}

        {/* Payment Breakdown */}
        {/* <section className="payment-breakdown">
          <h2>Payment Breakdown</h2>
          <ul>
            <li>Products Sold: ${paymentBreakdown.productsSold}</li>
            <li>Services Offered: ${paymentBreakdown.servicesOffered}</li>
            <li>Platform Fees Deducted: -${paymentBreakdown.platformFeesDeducted}</li>
          </ul>
        </section> */}

        {/* Transaction List */}
        {/* <section className="transaction-list">
          <h2>Transaction List</h2>
          <table>
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Items Purchased</th>
                <th>Amount Earned</th>
                <th>Status</th>
                <th>Date</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.transactionId}</td>
                  <td>{transaction.itemsPurchased}</td>
                  <td>${transaction.amountEarned}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.date}</td>
                  <td>
                    <button onClick={() => alert(`Invoice for ${transaction.transactionId} generated!`)}>
                      Generate Invoice
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section> */}

        {/* Withdrawals / Settlements */}
        <section className="withdrawals">
          {/* <h2>Withdrawals / Settlements</h2>
          <button onClick={this.handleRequestPayout}>Request Payout</button> */}
          <h3>Payout History</h3>
          <table>
            <thead>
              <tr>
                <th>Payout ID</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payoutHistory.map((payout, index) => (
                <tr key={index}>
                  <td>{payout.payoutId}</td>
                  <td>${payout.amount}</td>
                  <td>{payout.date}</td>
                  <td>{payout.status}</td>
                  {payout.status === "failed" || payout.status === "pending" ? (
        <td>
          <button onClick={() => this.handleRequestPayout(payout.payoutId)}>Request Payout</button>
        </td>
      ) : (
        // <td></td>
        <></>
      )}
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    );
  }
}

PaymentsPane.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  clearModalErrors: PropTypes.func.isRequired,
  products: PropTypes.oneOfType([PropTypes.object]).isRequired,
  categories: PropTypes.oneOfType([PropTypes.object]).isRequired
};

const mapStateToProps = state => ({ products: state.products, categories: state.categories });

const mapActionsToProps = {
  getProducts: productActions.getProducts,
  goToNextPage: productActions.goToNextPage,
  goToPrevPage: productActions.goToPrevPage,
  createProduct: productActions.createProduct,
  clearModalErrors: productActions.clearModalErrors,
  getCategories: categoryActions.getCategories
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PaymentsPane);
