import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../elements/Button';
const ViewProductModal = ({ product, isOpen, onClose }) => {
  const [currentImage, setCurrentImage] = useState(0);
//   const [quantity, setQuantity] = useState(1);

  const images = [
    product?.image,
    product?.image2,
    product?.image3,
    product?.image4,
    product?.image5
  ].filter(Boolean);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  if (!isOpen) return null;

  return (
    <div className="view-product-modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="product-gallery">
          <button className="nav-button prev" onClick={prevImage}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          
          <img 
            src={images[currentImage]} 
            alt={product.name} 
            className="product-image"
          />
          
          <button className="nav-button next" onClick={nextImage}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>

          <div className="image-thumbnails">
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail ${currentImage === index ? 'active' : ''}`}
                onClick={() => setCurrentImage(index)}
              />
            ))}
          </div>
        </div>

        <div className="product-info">
          <h2>{product.name}</h2>
          <p className="price">₦{product.price}</p>
          <p className="description">{product.description}</p>
        <div className="product-details">
            <p><strong>Category:</strong> {product.category.value}</p>
            {product.discount_story && (
                <p><strong>Special Offer:</strong> {product.discount_story}</p>
            )}
            {product.sizes && (
                <p><strong>Available Sizes:</strong> {product.sizes.join(', ')}</p>
            )}
            {product.colors && (
                <p><strong>Colors:</strong> {product.colors.join(', ')}</p>
            )}
            <div className="dimensions">
                {product.weight && <p><strong>Weight:</strong> {product.weight}kg</p>}
                {product.width && <p><strong>Width:</strong> {product.width}cm</p>}
                {product.height && <p><strong>Height:</strong> {product.height}cm</p>}
            </div>
            <p><strong>Quantity Available:</strong> {product.quantity}</p>
        </div>
          <div className="quantity-selector">
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProductModal;