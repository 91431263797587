import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer02';
import { Outlet } from 'react-router-dom';


const LayoutAlternative = ({ children }) => (
  <React.Fragment>
    <Header className="invert-color" navPosition="right" />
    <main className="site-content">
      {/* {children} */}
      <Outlet />
    </main>
    <Footer />
  </React.Fragment>
);

export default LayoutAlternative;  