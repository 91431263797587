// import React from 'react';
// import { Route } from 'react-router-dom';

// const AppRoute = ({
//   component: Component,
//   layout: Layout,
//   ...rest
// }) => {

//   Layout = (Layout === undefined) ? props => (<React.Fragment>{props.children}</React.Fragment>) : Layout;

//   return (
//     <Route
//       {...rest}
//       render={props => (
//         <Layout>
//           <Component {...props} />
//         </Layout>
//       )} />
//   );
// }

// export default AppRoute;

import React from 'react';
import { Outlet } from 'react-router-dom';

const AppRoute = ({ layout: Layout }) => {
  const RenderLayout = Layout || React.Fragment;

  return (
    <RenderLayout>
      <Outlet />
    </RenderLayout>
  );
};

export default AppRoute;
