/* eslint-disable react/react-in-jsx-scope */

import apiClient from "./apiConfig";

// auth

export const login = async (credentials) => {
    try {
      const response = await apiClient.post("/user/auth/login", credentials);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const register = async (formData) => {
    try {
      const response = await apiClient.post('/user/auth/register', {
        ...formData, // Pass email as username
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: "Something went wrong!" };
    }
  };

  
export const updateProfile = async (firstName, lastName, phone, image) => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const formData = new FormData();
    
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('phoneNumber', phone);
    
    if (image) {
      formData.append('image', image);
    }

    const response = await apiClient.post(
      `/user/${userData.id}/update`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Update failed" };
  }
};

export const verifyOTP = async (params) => {
  try {
    const response = await apiClient.post('/user/auth/verify-otp', params);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "OTP verification failed" };
  }
};

export const sendOTP = async (params) => {
  try {
    const response = await apiClient.post('/user/auth/send-otp', params);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to send OTP" };
  }
};

export const resetPassword = async (email, password) => {
  try {
    const response = await apiClient.post('/user/auth/reset-password', {
      email,
      password
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Password reset failed" };
  }
};

export const registerBusiness = async (businessData, image) => {
  try {
    const userData = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();
    
    formData.append('userId', userData.id);
    formData.append('businessName', businessData.businessName);
    formData.append('registered', businessData.registered);
    formData.append('type', businessData.type);
    formData.append('address', businessData.address);
    
    if (image) {
      formData.append('image', image);
    }

    const response = await apiClient.post('/business/register', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Business registration failed" };
  }
};

export const updateBusiness = async (businessData, image) => {
  try {
    const userData = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();
    
    Object.keys(businessData).forEach(key => {
      formData.append(key, businessData[key]);
    });
    
    formData.append('userId', userData.id);
    formData.append('businessId', userData.businessId);
    
    if (image) {
      formData.append('image', image);
    }

    const response = await apiClient.post('/business/update', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Business update failed" };
  }
};

export const getBusiness = async (id) => {
  try {
    const response = await apiClient.get(`/business/${id}`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to fetch business" };
  }
}
