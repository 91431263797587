// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';
// // import { useNavigate, useParams } from 'react-router-dom';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// export const AdminRoute = ({ component: Component, isAuthenticated, userRole, ...rest }) => {
//   // const navigate = useNavigate();
//   return (
//     <Route
//       {...rest}
//       render={() => {
//         if (isAuthenticated && (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_BUSINESS' || userRole === 'ROLE_USER')) {
//           return <Component />;
//         }else {
//           // navigate('/business')
//           return <Navigate to="/business" />;
//         }
//       }}
//     />
//   );
// };

// AdminRoute.propTypes = {
//   component: PropTypes.func,
//   isAuthenticated: PropTypes.bool,
//   userRole: PropTypes.string
// };

// AdminRoute.defaultProps = {
//   component: null,
//   isAuthenticated: false,
//   userRole: ''
// };

// const mapStateToProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated,
//   userRole: state.auth.userRole
// });

// export default connect(mapStateToProps)(AdminRoute);


// import React from 'react';
// import { Route, Navigate, Outlet } from 'react-router-dom';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

// export const AdminRoute = ({  isAuthenticated, userRole }) => {
//   if (!isAuthenticated || !(userRole === 'ROLE_ADMIN' || userRole === 'ROLE_BUSINESS' || userRole === 'ROLE_USER')) {
//     return <Navigate to="/business" />;
//   }
//   return <Outlet />;
//   // return <Route {...rest} component={Component} />;
// };

// AdminRoute.propTypes = {
//   // component: PropTypes.func,
//   isAuthenticated: PropTypes.bool,
//   userRole: PropTypes.string,
// };

// AdminRoute.defaultProps = {
//   // component: null,
//   isAuthenticated: false,
//   userRole: '',
// };

// const mapStateToProps = (state) => ({
//   isAuthenticated: state.auth.isAuthenticated,
//   userRole: state.auth.userRole,
// });

// export default connect(mapStateToProps)(AdminRoute);


import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AdminRoute = ({ isAuthenticated, userRole }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated or the role is valid
    if (!isAuthenticated && !(userRole === 'ROLE_ADMIN' || userRole === 'ROLE_BUSINESS' || userRole === 'ROLE_USER')) {
      setShouldRedirect(true);
    }
  }, [isAuthenticated]); // Depend on these values, so useEffect only triggers when they change

  if (shouldRedirect) {
    return <Navigate to="/business" />;
  }

  return <Outlet />;
};

AdminRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  userRole: PropTypes.string,
};

AdminRoute.defaultProps = {
  isAuthenticated: false,
  userRole: '',
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated, // Make sure it's checked just once, no constant update
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps)(AdminRoute);
