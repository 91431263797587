// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-page {
  font-family: "Sansita" !important;
  padding: 20px;
  background-color: #ffffff;
  min-width: 50%;
  max-width: 800px;
  margin: 10px auto;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab-button {
  flex: 1 1;
  padding: 10px;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.tab-button.active {
  border-bottom: 2px solid #22C4B5;
  color: #22C4B5;
}

.tab-content {
  margin-top: 20px;
}

.tab-section {
  display: none;
}

.tab-section.active {
  display: block;
}

h2 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Sansita";
}

p {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsPane/Settings.scss","webpack://./src/assets/styles/abstract/_colors.scss"],"names":[],"mappings":"AAEA;EAEE,iCAAA;EACA,aAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,aAAA;EACA,6BAAA;AAFF;;AAKA;EACE,SAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AAFF;;AAKA;EACE,gCAAA;EACA,cCzBM;ADuBR;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,eAAA;EACA,mBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,eAAA;AAFF","sourcesContent":["@import '../../assets/styles/abstract/index';\n\n.support-page {\n  // font-family: Arial, sans-serif;\n  font-family: 'Sansita' !important;\n  padding: 20px;\n  background-color: #ffffff;\n  min-width: 50%;\n  max-width: 800px;\n  margin: 10px auto;\n}\n\n.tabs {\n  display: flex;\n  border-bottom: 1px solid #ccc;\n}\n\n.tab-button {\n  flex: 1;\n  padding: 10px;\n  text-align: center;\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.tab-button.active {\n  border-bottom: 2px solid $green;\n  color: $green;\n}\n\n.tab-content {\n  margin-top: 20px;\n}\n\n.tab-section {\n  display: none;\n}\n\n.tab-section.active {\n  display: block;\n}\n\nh2 {\n  font-size: 20px;\n  margin-bottom: 15px;\n  font-family: 'Sansita';\n}\n\np {\n  font-size: 16px;\n}","$light-blue: #a9cfff;\n$dark-blue: #4297ff;\n$deep-black: #141414;\n$deep-green: #091D1B;\n$orange: #ff7a42;\n$green: #22C4B5;\n$yellow: #faf764;\n$light-grey: #eee;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
