import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions/authActions';
import './ForgotPassword.scss';
import { Navigate } from 'react-router-dom';
import ErrorToast from '../ErrorToast/ErrorToast';
import Spinner from '../Spinner/Spinner';
import logo from '../../assets/images/arena-logo-normal-svg.svg';

export class BizGetStarted extends Component {
  handleGetStarted = e => {
    e.preventDefault();
    const { sendOtp,
       auth: { loginErrors },
      clearLoginErrors
    } = this.props;
    if (loginErrors.length) clearLoginErrors();

    const email = this.email.value;
    const deviceId = "";
    sendOtp({ email, deviceId });
  };

  render() {

    const {
      auth: { otpSent, user, isLoading, loginErrors }
    } = this.props;

    if (otpSent) {
      return <Navigate to="/business/verify-otp" />;
    }
    
    return (
      <section className="form__wrapper">
        <div className="form">
          <img className="form__logo" src={logo} alt="logo" />
          <h4 className="form__appname">Store Manager</h4>
          <p className="form__header">Get Started</p>
          <p className="form__description">
          Enter personal account email address to becoming a merchant.</p>
          <form className="form__login" onSubmit={this.handleGetStarted}>
          {loginErrors.length ? <ErrorToast errors={loginErrors} /> : null}
            <div className="form__control">
            <input
                autoFocus
                type="email"
                id="login-email"
                className="form__input"
                placeholder="email@storemanager.com"
                required
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\+.*)$"
                ref={email => (this.email = email)}
              />
            </div>
            <div className="form__control">
              <button type="submit" className="btn btn--gradient full-width"disabled={isLoading ? true : null}>
              {isLoading ? <Spinner /> : 'GetStarted'}
              </button>
            </div>
            <p>Don't have personal account? <a href='../register'>Create one</a></p>
          </form>
        </div>
      </section>
    );
  }
}

BizGetStarted.propTypes = {
  sendOtp: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    otpSent: PropTypes.bool,
    user: PropTypes.string,
    isLoading: PropTypes.bool,
    loginErrors: PropTypes.array
  }).isRequired,
  clearLoginErrors: PropTypes.func.isRequired
};

const mapStateToProp = state => ({
  auth: state.auth
});

const mapActionsToProps = { sendOtp: authActions.sendOtp, clearLoginErrors: authActions.clearLoginErrors };

export default connect(mapStateToProp, mapActionsToProps)(BizGetStarted);