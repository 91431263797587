import React from 'react';
import Helmet from 'react-helmet';
import Main from '../../components/Main/Main';
import DefaultVerifyForm from '../../components/SetPassword/SetPasswordForm';

const SetPasswordPage = () => (
  <>
    <Helmet>
      <title>Set New Password - Arena Storefront</title>
    </Helmet>
    <Main>
      <DefaultVerifyForm />
    </Main>
  </>
);

export default SetPasswordPage;
