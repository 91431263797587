import {
  PROFILE_ACTION_START,
  GET_PROFILE,
  CLEAR_MODAL_ERRORS,
  PROFILE_ACTION_FAILURE,
  CREATE_PROFILE_SUCCESS
} from '../../actions/profileActions/profileActions';

const initalState = {
  vendor: {},
  meta: {},
  isLoading: false,
  modalErrors: [],
  modalLoading: false,
  message: ''
};

// const initalState = {
//   vendor: {}, // Default to an empty object
//   meta: {},
//   isLoading: false,
//   message: "",
// };

const profileReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case PROFILE_ACTION_START:
      return { ...state, ...payload };
    case GET_PROFILE:
      return { ...state, ...payload };
    case CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        vendor: [payload.vendor, ...state.vendor],
        message: payload.message,
        modalLoading: false
      };
    // case GET_PRODUCTS_NEXT_PAGE:
    //   return { ...state, ...payload };
    // case GET_PRODUCTS_PREV_PAGE:
    //   return { ...state, ...payload };
    case CLEAR_MODAL_ERRORS:
      return { ...state, modalErrors: [] };
    case PROFILE_ACTION_FAILURE:
      return { ...state, modalErrors: [...payload], modalLoading: false };
    default:
      return state;
  }
};

export default profileReducer;
