// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#site-footer {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
#site-footer p {
  font-size: 0.8rem;
}
@media (min-width: 1023px) {
  #site-footer p {
    font-size: 0.825rem;
  }
}
@media (min-width: 1503px) {
  #site-footer p {
    font-size: 0.9rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss","webpack://./src/assets/styles/abstract/_mixin.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,iBAAA;EACA,oBAAA;AADF;AAEE;EACE,iBAAA;AAAJ;ACNE;EDKA;IAGI,mBAAA;EAEJ;AACF;ACOE;EDbA;IAMI,iBAAA;EAIJ;AACF","sourcesContent":["@import '../../assets/styles/abstract/index';\n\n#site-footer {\n  text-align: center;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  & p {\n    font-size: 0.8rem;\n    @include desktop {\n      font-size: 0.825rem;\n    }\n    @include retina {\n      font-size: 0.9rem;\n    }\n  }\n}\n","@mixin desktop {\n  @media (min-width: #{$desktop-width}) {\n    @content;\n  }\n}\n\n@mixin tab {\n  @media (min-width: #{$tab-width}) {\n    @content;\n  }\n}\n\n@mixin mob-land {\n  @media (min-width: #{$mobile-landscape}) {\n    @content;\n  }\n}\n\n@mixin retina {\n  @media (min-width: #{$retina-width}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
