import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/LogoInvert';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import Input from '../elements/Input';
import Button from '../elements/Button';
import playstore from '../../assets/images/playstore.svg';
import appstore from '../../assets/images/appstore.svg';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer invert-color',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top text-xxs" style={{ width:"100%"}}>
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <FooterSocial />
                  <div>
            <img src={playstore} />
            <img src={appstore} />
          </div>
                  
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Links</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/shop">Shop</a>
                    </li>
                    <li>
                      <a href="https://blog.thearenang.com">Blog</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Help</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/payment_options">FAQs</a>
                    </li>
                    <li>
                      <a href="/business/register">Become a Merchant</a>
                    </li>
                    {/* <li>
                      <a href="https://cruip.com/">Become an Ambassador</a>
                    </li> */}
                    <li>
                      <a href="/terms">Terms of Use</a>
                    </li>
                    <li>
                      <a href="/privacy">Privacy Policies</a>
                    </li>
                    <li>
                      <a href="/returns">Return / Refund Policies</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Newsletter</div>
                  <div>
                    <Input/>
                    <Button className="button-primary">Subscribe</Button>
                  </div>
                  {/* <ul className="list-reset mb-0">
                    <li>
                      <a href="https://cruip.com/">The purpose of lorem</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Dummy text used</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Excepteur sint</a>
                    </li>
                    <li>
                      <a href="https://cruip.com/">Occaecat cupidatat</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <hr width="100%"/>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              {/* <FooterNav /> */}
              <div className="footer-copyright">&copy; 2020 Arena, all rights reserved</div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;