import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as vendorActions from '../../actions/profileActions/profileActions';
import './Settings.scss';
import Loading from '../Loading/Loading';
import { updateProfile } from '../../network/api';
import FlutterwaveApi from '../../network/flutterwave_api';
import Util from '../../utils';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "personal-info",
    }
   
  }

  getBanks = async () => {
    try {
      const banks = await Util.makeRequest('/payments/banks', 'GET');

      this.setState({ banks:banks.message });
    } catch (error) {
    }

  }
  
  componentDidMount() {
    const { getProfile } = this.props;
    getProfile(); // Fetch vendor details
    this.getBanks();

   
  }

  // componentDidUpdate(prevProps) {
  //   // Update state when vendor props change
  //   if (prevProps.vendor !== this.props.vendor) {
  //     const { vendor } = this.props;
  //     this.setState({
  //       contactNumber: vendor.contactNumber || '',
  //       registered: vendor.registered || '',
  //       cac: vendor.cac || '',
  //       // ...other fields
  //     });
  //   }
  // }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAccountSave = () => {
    const { updateProfile, clearModalErrors } = this.props;
    const user = JSON.parse(localStorage.getItem('userInfo'));
    // Integrate API call here
    const formData = new FormData();
    formData.append('businessId', user.businessId);
    formData.append('userId', user.id);

    updateProfile(formData,clearModalErrors);

  };


  handleFileUpload = (e) => {
    const { updateProfile, clearModalErrors } = this.props;
    const user = JSON.parse(localStorage.getItem('userInfo'));
    this.setState({ logo: e.target.files[0] });
    const formData = new FormData();
    formData.append('businessId', user.businessId);
    formData.append('userId', user.id);
    // formData.append('firstName', this.state.firstName);
    // formData.append('lastName', this.state.lastName);

    updateProfile(formData,clearModalErrors);
  };

  handleSave = () => {
    const { updateProfile, clearModalErrors } = this.props;
    const user = JSON.parse(localStorage.getItem('userInfo'));
    // Integrate API call here
    const formData = new FormData();
    formData.append('businessId', user.businessId);
    formData.append('userId', user.id);
    this.state.storeName ? formData.append('businessName', this.state.storeName) : formData.append(null, '');
    this.state.description ? formData.append('description', this.state.storeDescription) : formData.append(null, '');
    this.state.address ? formData.append('address', this.state.address) : formData.append(null, '');

    updateProfile(formData,clearModalErrors);
  };
  
  handlePaymentSave = () => {
    const { updateProfile, clearModalErrors } = this.props;

    // if (loginErrors.length) clearLoginErrors();
    // const body = {
    //   accountNum: this.state.bankAccountNumber,
    //   bankName: this.state.bankName,
    //   bankCode: this.state.bankCode
    // }
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const formData = new FormData();
    formData.append('businessId', user.businessId);
    formData.append('userId', user.id);
    this.state.bankAccountNumber ? formData.append('accountNum', this.state.bankAccountNumber) : formData.append(null, '');
    this.state.bankName ? formData.append('bankName', this.state.bankName) : formData.append(null, '');
    this.state.bankCode ? formData.append('bankCode', this.state.bankCode) : formData.append(null, '');
    // Integrate API call here
    updateProfile(formData,clearModalErrors);

  };

  // Method to handle tab click and set the active tab
  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const user = localStorage.getItem('userInfo');
    // if (user) {
      const userData = JSON.parse(user);
    const { vendorDetail,
      vendorDetail: { vendor, meta, isLoading, modalErrors, modalLoading }
    } = this.props;
    const { activeTab,
      storeName = vendor.businessName, 
      storeDescription = vendor.description, 
      contactNumber = userData.phoneNumber, 
      registered = vendor.registered, 
      address = vendor.address,
      email = userData.email, 
      firstName = userData.firstName, 
      lastName = userData.lastName,
      password, 
      bankAccountNumber = vendor.accountNum, 
      bankName = vendor.bankName, 
      bankAccontName,
      bankCode,
      cac = vendor.cac, 
      biz_type = vendor.type, 
      id_card = vendor.idCard, 
      poa = vendor.poa } = this.state;

    if (isLoading) {
      return <Loading title="Loading Vendor Profile..." />;
    }
    
    return (
      <div className="support-page">
        {/* Tabs */}
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === "personal-info" ? "active" : ""}`}
            onClick={() => this.handleTabClick("personal-info")}
          >
            Store
          </button>
          <button
            className={`tab-button ${activeTab === "account" ? "active" : ""}`}
            onClick={() => this.handleTabClick("account")}
          >
            Account
          </button>
          <button
            className={`tab-button ${activeTab === "payment" ? "active" : ""}`}
            onClick={() => this.handleTabClick("payment")}
          >
            Payment
          </button>
          <button
            className={`tab-button ${activeTab === "opening" ? "active" : ""}`}
            onClick={() => this.handleTabClick("opening")}
          >
            Openings
          </button>
          {/* <button
            className={`tab-button ${activeTab === "security" ? "active" : ""}`}
            onClick={() => this.handleTabClick("security")}
          >
            Security
          </button>
          <button
            className={`tab-button ${activeTab === "notifications" ? "active" : ""}`}
            onClick={() => this.handleTabClick("notifications")}
          >
            Notifications
          </button> */}
        </div>

        {/* Subsection content */}
        <div className="tab-content">
          <div
            id="personal-info"
            className={`tab-section ${activeTab === "personal-info" ? "active" : ""}`}
          >
            <h2>Profile</h2>
            <form>
          <label>Upload Logo:</label>
          <input type="file" accept="image/*" onChange={this.handleFileUpload} />

          <label>Store Name:</label>
          <input
            type="text"
            name="storeName"
            value={storeName}
            onChange={this.handleInputChange}
          />

          <label>Store Description:</label>
          <textarea
            name="storeDescription"
            value={storeDescription}
            onChange={this.handleInputChange}
          />

          <label>Business Type:</label>
          <input
            type="text"
            name="biz_type"
            value={this.state.biz_type}
            onChange={this.handleInputChange}
          />

          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={address}
            onChange={this.handleInputChange}
          />

          <label>Contact Number:</label>
          <input
            type="tel"
            name="contactNumber"
            value={contactNumber}
            onChange={this.handleInputChange}
            disabled
          />

          <label>Registered:</label>
          <input
            name="registered"
            value={registered}
            onChange={this.handleInputChange}
          />

          <label>CAC:</label>
          <input
            type="text"
            name="cac"
            value={cac}
            onChange={this.handleInputChange}
          />

          <label>ID Card:</label>
          <input
            type="text"
            name="id_card"
            value={id_card}
            onChange={this.handleInputChange}
          />

          <label>POA:</label>
          <input
            type="text"
            name="poa"
            value={poa}
            onChange={this.handleInputChange}
          />


          <button type="button" onClick={this.handleSave}>
            Save Changes
          </button>
        </form>
          </div>

        <div id="account"
            className={`tab-section ${activeTab === "account" ? "active" : ""}`}
          >
          <form>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={this.handleInputChange}
              disabled
            />

            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={this.handleInputChange}
              disabled
            />

            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
            />

            {/* <label>New Password:</label>
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                  /> 

                  <button type="button" onClick={this.handleAccountSave}>
                    Save Changes
                  </button> */}
                  </form>
                  </div>

                  <div id="payment"
                    className={`tab-section ${activeTab === "payment" ? "active" : ""}`}
                  >
                    <form>
                    <h2>Payment Settings</h2>
                    <label>Bank Account Number:</label>
                    <input
                      type="text"
                      name="bankAccountNumber"
                      value={bankAccountNumber}
                      onChange={this.handleInputChange}
                      required
                    />
                    
                    <label>Bank Name:</label>
                    <select
                      name="bankName"
                      value={bankName}
                      onChange={(e) => {
                      const selectedBank = e.target.options[e.target.selectedIndex];
                      this.setState({
                        bankName: selectedBank.value,
                        bankCode: selectedBank.dataset.code
                      });
                      }}
                      required
                    >
                      <option value="">Select a bank</option>
                      {this.state.banks && this.state.banks.map(bank => (
                      <option 
                        key={bank.id} 
                        value={bank.name}
                        data-code={bank.code}
                        >
                        {bank.name}
                      </option>
                      ))}
                      
                    </select>
                    {/* <label>Account Name:</label> */}

                          <button 
                            type="button" 
                            onClick={this.handlePaymentSave}
                            disabled={modalLoading}
                          >
                            {modalLoading ? "Saving..." : "Save Changes"}
                          </button>
                          
                        </form>
                        </div>

                        <div
                          id="opening"
                          className={`tab-section ${activeTab === "opening" ? "active" : ""}`}
                          >
                          <h2>Opening Hours</h2>
                          {/* <p>This section allows users to update their opening hours.</p> */}

            <form>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                <div key={day} className="opening-hours-row">
                  <label>{day}:</label>
                  <select 
                    name={`${day.toLowerCase()}-status`} 
                    onChange={this.handleInputChange}
                  >
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </select>
                  
                  <div className="time-inputs">
                    <input
                      type="time"
                      name={`${day.toLowerCase()}-open`}
                      onChange={this.handleInputChange}
                      disabled={this.state[`${day.toLowerCase()}-status`] === 'closed'}
                    />
                    <span>to</span>
                    <input
                      type="time"
                      name={`${day.toLowerCase()}-close`}
                      onChange={this.handleInputChange}
                      disabled={this.state[`${day.toLowerCase()}-status`] === 'closed'}
                    />
                  </div>
                </div>
              ))}
              <button type="button" onClick={this.handleOpeningHoursSave}>
                Save Opening Hours
              </button>
            </form>

          </div>

          {/* <div
            id="security"
            className={`tab-section ${activeTab === "security" ? "active" : ""}`}
          >
              value={bankName}
            <p>This section allows users to update their password and security settings.</p>
          </div>

          <div
            id="notifications"
            className={`tab-section ${activeTab === "notifications" ? "active" : ""}`}
          >
            <h2>Notifications</h2>
            <p>This section manages how users get notifications.</p>
          </div> */}
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  getProfile: PropTypes.func.isRequired,
  vendorDetail: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateProfile: PropTypes.func.isRequired,
//   // fetchVendorProducts: PropTypes.func.isRequired,
//   // createProduct: PropTypes.func.isRequired,
//   // deleteProduct: PropTypes.func.isRequired,
//   // updateProduct: PropTypes.func.isRequired,
  clearModalErrors: PropTypes.func.isRequired,
//   // vendorDetail: PropTypes.shape({
//     vendorDetail :PropTypes.object.isRequired,
//   // products: PropTypes.array.isRequired,
//   isLoading: PropTypes.bool,
//   modalErrors: PropTypes.array,
//   modalLoading: PropTypes.bool,
// // }).isRequired,
};

const mapStateToProps = (state) => {
  return ({
    vendorDetail: state.profile,
    // products: state.vendor.products,
    isLoading: state.isLoading,
    modalErrors: state.modalErrors,
    modalLoading: state.modalLoading,
  });
}

const mapActionsToProps = {
  getProfile: vendorActions.getProfile,
  updateProfile: vendorActions.updateProfile,
//   // fetchVendorProducts: vendorActions.fetchVendorProducts,
//   // createProduct: vendorActions.createProduct,
//   // deleteProduct: vendorActions.deleteProduct,
//   // updateProduct: vendorActions.updateProduct,
  clearModalErrors: vendorActions.clearModalErrors,
};

export default Settings = connect(mapStateToProps, mapActionsToProps)(Settings);
