import React from 'react';
import Header from '../components/layout/Header';
import { Outlet } from 'react-router-dom';

const LayoutDefault = ({ children }) => (
  <React.Fragment>
    {/* <Header navPosition="right" hideNav /> */}
    <main className="site-content">
      {/* {children} */}
      <Outlet />
    </main>
  </React.Fragment>
);

export default LayoutDefault;  