import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from 'react-router-dom';
import PageHeader from "../components/sections/PageHeader";
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import Button from "../components/elements/Button";
import { deliveryAPI } from "../network/delivery_api";
import { _ } from "ajv";

const CheckoutPage = () => {

    const location = useLocation();
    var { vendor } = location.state != null ? location.state : {return: <Navigate to="/shop" replace />};
    const user = JSON.parse(localStorage.getItem('user'));

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [selectedRider, setSelectedRider] = useState(null);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [deliveryDuration, setDeliveryDuration] = useState('');
    const [logisticsCompanies, setLogisticsCompanies] = useState([]);
    const [redisKey, setRedisKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
    const [couponError, setCouponError] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [apartment, setApartment] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber ? user.phoneNumber.toString() : '');
    const [deliveryInstructions, setDeliveryInstructions] = useState('');
    const [serviceFee, setServiceFee] = useState(0);
    const [total, setTotal] = useState(0);

    var subtotal = 0;
    // const subtotal = vendor.map((acc, vendor) => {
        //     return acc + vendor.items.reduce((vendorAcc, item) => vendorAcc + item.quantity * item.price, 0);
        // }, 0);
    // const subtotal = 0;
    vendor != null ? 
    vendor.forEach (vendor => {
        subtotal += vendor.items.reduce((acc, item) => acc + item.quantity * item.price, 0);
    }) :
    window.location.href = '/cart';
    
    useEffect(() => {
        if (!user) {
            localStorage.setItem('redirectAfterLogin', window.location.pathname);
                window.location.href = '/login';
                return;
        }
        if (vendor == null) {
            alert("There is no item in checkout process. Kindly proceed to your cart");
        }
        
        const sub = subtotal * 0.1;
        setServiceFee (sub);
        setTotal(subtotal + serviceFee + deliveryFee - couponDiscount);
    }, [deliveryFee,couponDiscount]);

    var items_list = [];


   
    // const logisticsCompanies = [
    //     { name: 'Logistics 1', price: 10, duration: '30 mins' },
    //     { name: 'Logistics 2', price: 15, duration: '45 mins' },
    //     { name: 'Logistics 3', price: 20, duration: '1 hour' }
    // ];

    const handlePlaceSelect = (place) => {
        setAddress(place.formatted_address);
    };

    const handleRiderSelect = (rider) => {
        setDeliveryFee(0)
        setCouponDiscount(0)
        setSelectedRider(rider.courier.id);
        setDeliveryFee(rider.amount);
        setDeliveryDuration(rider.duration);
    };

    const handleApplyCoupon = async () => {
        setIsApplyingCoupon(true);
        try {
            // Simulate applying coupon
            setTimeout(() => {
                if (couponCode === 'FREEDELIVERY') {
                    setCouponDiscount(deliveryFee);
                } else {
                    setCouponError('Invalid coupon code');
                }
                setIsApplyingCoupon(false);
            }, 2000);
        } catch (err) {
            setIsApplyingCoupon(false);
        }
    };

    // setPhoneNumber(user.phoneNumber);

    const config = user ? {
        // public_key: 'FLWPUBK_TEST-e571f1dc4b9768d37c81409892b4f23e-X',
        public_key: 'FLWPUBK-960d0ee588adca2f493ee8d119444eee-X',
        tx_ref: Date.now(),
        amount: total,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: user.email,
          phone_number: user.phoneNumber,
          name: user.firstName + ' ' + user.lastName,
        },
        customizations: {
          title: 'The ArenaNG',
          description: 'Payment for items in cart',
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    } : {};
    
      const handleFlutterPayment = useFlutterwave(config);



    return (
        <React.Fragment>
            <PageHeader page="Checkout" />
            <div className="checkout-container">
                <div className="checkout-left">
                    <h2>Delivery Address</h2>
                    <div className="address-form"></div>
                        <LoadScript googleMapsApiKey="AIzaSyA0eTqZ5UtsrQjT9AOO23pLNYnOt9yOujk" libraries={['places']}>
                            <Autocomplete
                                onPlaceSelected={handlePlaceSelect}
                                types={['address']}
                                componentRestrictions={{ country: 'NG' }}
                            >
                                <input
                                    type="text"
                                    placeholder="Enter your street address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </Autocomplete>
                        </LoadScript>
                        <input
                            type="text"
                            placeholder="Apartment/Suite/Unit (optional)"
                            onChange={(e) => setApartment(e.target.value)}
                        />
                        <div className="address-details">
                            <input
                                type="text"
                                placeholder="City"
                                required
                                onChange={(e) => setCity(e.target.value)}
                            />
                            <select 
                                required
                                onChange={(e) => setState(e.target.value)}
                            >
                                <option value="">Select State</option>
                                <option value="Lagos">Lagos</option>
                                <option value="Abuja">Abuja</option>
                                <option value="Rivers">Rivers</option>
                                {/* Add other Nigerian states */}
                            </select>
                            <select
                                required 
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                <option value="Nigeria">Nigeria</option>
                            </select>
                        </div>
                        <input
                            type="text"
                            placeholder="Phone Number"
                            required
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Delivery Instructions (optional)"
                            onChange={(e) => setDeliveryInstructions(e.target.value)}
                        />
                        <Button 
                            className="button-primary" 
                            disabled={isLoading || !address || !phoneNumber}
                            onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                        vendor.forEach(
                                            (biz) => biz.items.map((element) => {
                                                items_list.push({
                                                name: element.product,
                                                category: element.category,
                                                description: element.description,
                                                weight: element.weight != null ? element.weight : 1,
                                                amount: element.price < 500 ? 500 : element.price,
                                                quantity: element.quantity
                                            })
                                            
                                        }
                                        )
                                    );
                                        var params = {
                                            "type": !country ? "interstate" : 
                                                (vendor[0].country === country ? 
                                                (vendor[0].state === state ? "intrastate" : "interstate") 
                                                : "international"),
                                            "toAddress": {
                                              "name": user.firstName + ' ' + user.lastName,
                                              "email": user.email,
                                              "address": address + ', ' + apartment + ', ' + city + ', ' + state + ', ' + country,
                                              "phone": phoneNumber.startsWith('+234') ? '0' + phoneNumber.slice(4) : phoneNumber,
                                            },
                                            "fromAddress": {
                                              "name": vendor[0].vendor,
                                              "email": vendor[0].email, 
                                              "address": vendor[0].address + ', ' + vendor[0].city + ', ' + vendor[0].state + ', ' + vendor[0].country,
                                              "phone": vendor[0].phone.startsWith('+234') ? '0' + vendor[0].phone.slice(4) : vendor[0].phone,
                                            },
                                            "parcels": {
                                              "width": 32.5,
                                              "length": 32.5,
                                              "height": 32.5,
                                              "weight": 2
                                            },
                                            "items":items_list
                                          };
                                        deliveryAPI.getAllRates(params).then(response => {
                                            
                                            const rates = response.data.data.rates;
                                            const filteredRates = rates.filter((element) => element["status"] !== false);
                                            setRedisKey(response.data.data.redis_key);
                                            setLogisticsCompanies(filteredRates);
                                            
                                        }).catch(err => {
                                            alert('Error fetching delivery rates');
                                        })
                                        .finally(() => {
                                            setIsLoading(false);
                                        });
                                    } catch (err) {
                                        setIsLoading(false);
                                    }
                                }}
                            >
                                {isLoading ? 'Loading...' : 'Get Delivery Options'}
                            </Button>
                    {
                        logisticsCompanies.length > 0 && (
                            <React.Fragment>
                                <h2>Delivery Options</h2>
                                <ul>
                                    {logisticsCompanies.map((logisticsCompany, index) => (
                                        <li key={index} onClick={() => handleRiderSelect(logisticsCompany)}>
                                           <img src={logisticsCompany.courier.icon} style={{width:"30px",    display: "inline-flex"}}/>  {logisticsCompany.courier.name} - ₦{logisticsCompany.amount} - {logisticsCompany.estimated_days}
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )
                    }
                    {/* <h2>Select Rider</h2>
                    <ul>
                        {logisticsCompanies.map((rider, index) => (
                            <li key={index} onClick={() => handleRiderSelect(rider)}>
                               <img src={rider.courier.icon} style={{width:"50px",    display: "inline-flex"}}/> {rider.courier.name} - ₦{rider.amount} - {rider.estimated_days}
                            </li>
                        ))}
                    </ul> */}
                </div>
                <div className="checkout-right">
                    <h2>Summary</h2>
                    <p>Items Total: ₦{subtotal.toFixed(2)}</p>
                    <p>Service Fee (10%): ₦{serviceFee.toFixed(2)}</p>
                    <p>Delivery Fee: ₦{deliveryFee.toFixed(2)}</p>
                    <p>Sub-Total: ₦{(subtotal + serviceFee + deliveryFee).toFixed(2)}</p>
                    <div className="coupon-section">
                        <h2>Apply Coupon</h2>
                        <div className="coupon-input" style={{display: 'flex', gap: '10px'}}>
                            <input 
                                type="text" 
                                placeholder="Enter coupon code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                            />
                            <Button 
                                className="button-secondary"
                                onClick={handleApplyCoupon}
                                disabled={isApplyingCoupon}
                            >
                                {isApplyingCoupon ? 'Applying...' : 'Apply'}
                            </Button>
                        </div>
                        {couponError && <p className="error-message">{couponError}</p>}
                        {couponDiscount > 0 && (
                            <p className="discount-amount">Coupun Discount: ₦{couponDiscount.toFixed(2)}</p>
                        )}
                    </div>
                    &nbsp;
                    {/* <h2>Payment Options</h2>
                    <select>
                        <option value="credit-card">Credit Card</option>
                        <option value="paypal">PayPal</option>
                        <option value="bank-transfer">Bank Transfer</option>
                    </select> */}
                    <p>Total: ₦{(total).toFixed(2)}</p>
                    <Button className="button-primary" 
        disabled={!selectedRider || isLoading}
        onClick={() => {
            if (!selectedRider) {
                alert('Please select a delivery option first');
                return;
            }
            setIsLoading(true);
            handleFlutterPayment({
                callback: (response) => {
                    closePaymentModal(); // this will close the modal programmatically
                    setIsLoading(false);
                },
                onClose: () => {
                    setIsLoading(false);
                },
            });
        }}>
        {isLoading ? 'Processing...' : 'Proceed to Payment'}
    </Button>
                </div>
            </div>
        </React.Fragment>
    );

    
}

export default CheckoutPage;